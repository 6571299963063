import { useEffect, useState } from 'react';
import AdminUI from './AdminUI';
import { FLOW_ORG_PAGES } from '../../constants';
import { resolveOrgUserRole } from '../../utils/organization';
import { useAuth } from '../AuthProvider';
import PageSwitcher from '../generic/PageSwitcher';
import Loader from '../loader/Loader';
import NonAdminUI from './NonAdminUI';
import LocalRuntimeDisplayToken from './localRuntimes/LocalRuntimeDisplayToken';
import LocalRuntimeEditor from './localRuntimes/LocalRuntimeEditor';
import OrganizationInviteProvider from './OrganizationInviteProvider';

const InitialOrgPage = () => {
    const [orgPageId, setOrgPageId] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const { user, tenant, fetchUser } = useAuth();

    useEffect(() => {
        // We need to wait for the user to be loaded every time we enter this screen
        // in case the user has been removed from the current tenant's organization
        const fetchCurrentUser = async () => {
            setIsLoading(true);
            await fetchUser();
            setIsLoading(false);
        };

        fetchCurrentUser();
    }, [fetchUser]);

    useEffect(() => {
        if (!tenant || !user) {
            return;
        }

        const { isUserAdmin, isTenantInOrg } = resolveOrgUserRole(user, tenant);
        const pageId =
            isUserAdmin && isTenantInOrg
                ? FLOW_ORG_PAGES.flowOrgAdminUI
                : FLOW_ORG_PAGES.flowOrgJoinedUI;

        setOrgPageId(pageId);
    }, [user, tenant]);

    if (isLoading) {
        return <Loader />;
    }

    if (!orgPageId) {
        return null;
    }

    return (
        <OrganizationInviteProvider>
            <PageSwitcher
                pages={[
                    {
                        id: FLOW_ORG_PAGES.flowOrgAdminUI,
                        label: 'Organization Settings',
                        parent: null,
                        menu: [
                            {
                                label: 'Current Organization',
                                link: '#organizationDetails',
                                visible: true,
                            },
                            {
                                label: 'Organization Invitations',
                                link: '#organizationInvitations',
                                visible: true,
                            },
                            {
                                label: 'Tenants',
                                link: '#organizationTenants',
                                visible: true,
                            },
                            {
                                label: 'Administrators',
                                link: '#organizationAdministrators',
                                visible: true,
                            },
                            {
                                label: 'Local Runtimes',
                                link: '#localRuntimes',
                                visible: true,
                            },
                        ],
                        component: AdminUI,
                    },
                    {
                        id: FLOW_ORG_PAGES.flowOrgJoinedUI,
                        label: 'Organization Settings',
                        parent: null,
                        menu: null,
                        component: () => <NonAdminUI tenant={tenant} />,
                    },
                    {
                        id: FLOW_ORG_PAGES.localRuntimeCreate,
                        label: 'Create Local Runtime',
                        parent: FLOW_ORG_PAGES.flowOrgAdminUI,
                        menu: null,
                        component: (props) => <LocalRuntimeEditor {...props} />,
                    },
                    {
                        id: FLOW_ORG_PAGES.localRuntimeEdit,
                        label: 'Edit Local Runtime',
                        parent: FLOW_ORG_PAGES.flowOrgAdminUI,
                        menu: null,
                        component: (props) => <LocalRuntimeEditor {...props} />,
                    },
                    {
                        id: FLOW_ORG_PAGES.localRuntimeDisplayToken,
                        label: 'New Local Runtime Token',
                        parent: FLOW_ORG_PAGES.flowOrgAdminUI,
                        menu: null,
                        component: LocalRuntimeDisplayToken,
                    },
                ]}
                wrapperSelector="flow-organisation"
                initialPageId={orgPageId}
            />
        </OrganizationInviteProvider>
    );
};

export default InitialOrgPage;
