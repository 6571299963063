import type { ItemCollectionResponse } from '../types';
import type {
    Environment,
    EnvironmentFlow,
    EnvironmentVariable,
    GetEnvironmentVariablesRequest,
    GetEnvironmentFlowsRequest,
} from '../types/environment';
import { fetchAndParse } from '../utils/ajax';
import { getTenantId } from '../utils/tenant';

export const getAllEnvironments = (): Promise<Environment[]> =>
    fetchAndParse({
        url: '/api/environment/1',
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ManyWhoTenant: getTenantId(),
        },
    });

export const saveEnvironment = (environment: Partial<Environment>): Promise<Environment> =>
    fetchAndParse({
        url: '/api/environment/1',
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ManyWhoTenant: getTenantId(),
        },
        body: environment,
    });

export const deleteEnvironment = (environmentId: string) =>
    fetchAndParse({
        url: `/api/environment/1/${environmentId}`,
        method: 'delete',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ManyWhoTenant: getTenantId(),
        },
    });

export const getEnvironmentFlows = (
    request: GetEnvironmentFlowsRequest,
): Promise<ItemCollectionResponse<EnvironmentFlow>> =>
    fetchAndParse({
        url: `/api/environment/1/${request.environmentId}/flows`,
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ManyWhoTenant: getTenantId(),
        },
        body: request,
    });

export const getEnvironmentVariables = (
    environmentId: string,
    request: GetEnvironmentVariablesRequest,
): Promise<ItemCollectionResponse<EnvironmentVariable>> =>
    fetchAndParse({
        url: `/api/environment/1/${environmentId}/environmentvariables`,
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ManyWhoTenant: getTenantId(),
        },
        body: request,
    });

export const saveEnvironmentVariable = (
    environmentId: string,
    environmentVariable: EnvironmentVariable,
) =>
    fetchAndParse({
        url: `/api/environment/1/${environmentId}/environmentvariable/`,
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ManyWhoTenant: getTenantId(),
        },
        body: environmentVariable,
    });

export const deleteEnvironmentVariable = (environmentId: string, environmentVariableId: string) =>
    fetchAndParse({
        url: `/api/environment/1/${environmentId}/environmentvariable/${environmentVariableId}`,
        method: 'delete',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ManyWhoTenant: getTenantId(),
        },
    });

export const getDevelopmentEnvironment = (): Promise<Environment> =>
    fetchAndParse({
        url: '/api/environment/1/type/development',
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ManyWhoTenant: getTenantId(),
        },
    });
