import Header from './header/Header';
import Footer from './Footer';
import NotificationList from './notifications/NotificationList';
import Tenant from './Tenant';
import FeatureFlagWrapper from './featureFlags/FeatureFlagWrapper';
import AdminSidebar from './AdminSidebar';
import TenantSwitcher from './masthead/TenantSwitcher';
import IdleTimeout from './authentication/IdleTimeout';
import { useState } from 'react';
import { CollaborationProvider } from '../collaboration/CollaborationProvider';
import { notifyError } from '../../js/actions/reduxActions/notification';
import { connect, type ConnectedProps } from 'react-redux';

type ToolingProps = { currentLoggedInTenantId: string | undefined } & ConnectedProps<
    typeof connector
>;

const connector = connect(null, { notifyError });

/**
 *
 * @description The base component that renders all parts of the application that needs
 * to display the header and footer.
 */
const Tooling = ({ currentLoggedInTenantId }: ToolingProps) => {
    const [isTenantSelectorOpen, setIsTenantSelectorOpen] = useState(false);

    const onCloseTenantSelector = () => setIsTenantSelectorOpen(false);
    const openTenantSelector = () => setIsTenantSelectorOpen(true);

    return (
        <div className="draw">
            <Header openTenantSelector={openTenantSelector} />
            <AdminSidebar />
            {isTenantSelectorOpen || !currentLoggedInTenantId ? (
                <TenantSwitcher onClose={onCloseTenantSelector} />
            ) : null}
            {currentLoggedInTenantId && (
                <>
                    <IdleTimeout />
                    <FeatureFlagWrapper tenantId={currentLoggedInTenantId}>
                        <CollaborationProvider tenantId={currentLoggedInTenantId}>
                            <div className="tab-outer">
                                <Tenant isTenantSelectorOpen={isTenantSelectorOpen} />
                            </div>
                        </CollaborationProvider>
                    </FeatureFlagWrapper>
                </>
            )}
            <Footer />
            <NotificationList />
        </div>
    );
};

export default connector(Tooling);
