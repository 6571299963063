import FormGroup from '../../../generic/FormGroup';
import IdentityProviderAttributeMappings from '../IdentityProviderAttributeMappings';
import { ENGINE_API_URL } from '../../../../constants';
import type { SAMLIdentityProviderAPI } from '../../../../types';
import { useAuth } from '../../../AuthProvider';
import { useEffect, useState } from 'react';
import { getAllEnvironments } from '../../../../sources/environments';
import type { Environment } from '../../../../types/environment';
import '../../../../../../css/identity-providers.css';

interface Props {
    item: SAMLIdentityProviderAPI;
    updateItem: (item: Partial<SAMLIdentityProviderAPI>) => void;
    hasSubmitted: boolean;
}

const IdentityProviderSAML = ({ item, updateItem, hasSubmitted }: Props) => {
    const { tenant } = useAuth();
    const [environments, setEnvironments] = useState<Environment[]>([]);

    useEffect(() => {
        const loadEnvironments = async () => {
            setEnvironments(await getAllEnvironments());
        };

        if (tenant?.tenantSettings?.environments) {
            loadEnvironments();
        }
    }, [tenant?.tenantSettings?.environments]);

    const isInactiveSessionTimeoutValid = () => {
        if (item.inactiveSessionTimeout === null) {
            return false;
        }

        return item.inactiveSessionTimeout > 0;
    };

    const isActiveSessionTimeoutValid = () => {
        if (item.activeSessionTimeout === null || item.inactiveSessionTimeout === null) {
            return false;
        }

        return (
            item.activeSessionTimeout > 0 &&
            item.activeSessionTimeout >= item.inactiveSessionTimeout
        );
    };

    const onAttributeMappingChange = (name: string, value: string) => {
        updateItem({
            attributeMappings: {
                ...item.attributeMappings,
                [name]: value,
            },
        });
    };

    const metadataUrl = `${ENGINE_API_URL ?? ''}/api/identityprovider/1/${tenant?.id}/${
        item.id
    }/saml/metadata`;

    let metadata = (
        <a
            href={metadataUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="identity-prodiver-saml-metadata"
        >
            {metadataUrl}
        </a>
    );

    if (tenant?.tenantSettings?.environments) {
        metadata = (
            <div className="identity-prodiver-saml-metadata-environments">
                {environments.map((environment) => (
                    <a
                        href={`${metadataUrl}?environment=${environment.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        key={environment.id}
                    >
                        {environment.name}
                    </a>
                ))}
            </div>
        );
    }

    return (
        <>
            <FormGroup label="Flow (Service Provider) Metadata">
                {item.id ? (
                    <>
                        {metadata}
                        <span className="help-block">
                            Service Provider metadata generated for each environment in this tenant
                        </span>
                    </>
                ) : (
                    <p>
                        <strong>
                            SAML identity provider must be saved first to create its metadata
                        </strong>
                    </p>
                )}
            </FormGroup>

            <FormGroup
                label="Identity Provider Metadata"
                htmlFor="identity-provider-idp-metadata"
                isRequired={false}
            >
                <textarea
                    id="identity-provider-idp-metadata"
                    className="form-control form-control-textarea"
                    value={item.metadata}
                    onChange={(e) => updateItem({ metadata: e.target.value })}
                    rows={8}
                />
            </FormGroup>

            <FormGroup label="Entity Id" htmlFor="identity-provider-entityId" isRequired={false}>
                <input
                    id="identity-provider-entityId"
                    className="form-control form-control-long"
                    value={item.entityId}
                    onChange={(e) => updateItem({ entityId: e.target.value })}
                    type="string"
                />
            </FormGroup>

            <FormGroup
                label="Allowed Audience"
                htmlFor="identity-provider-allowed-audience"
                isRequired={false}
            >
                <input
                    id="identity-provider-allowed-audience"
                    className="form-control form-control-long"
                    value={item.allowedAudience}
                    onChange={(e) => updateItem({ allowedAudience: e.target.value })}
                    type="string"
                />
                <span className="help-block">This must match the audience tag</span>
            </FormGroup>

            <FormGroup
                label="Inactive Session Timeout (minutes)"
                htmlFor="identity-provider-inactive-session-timeout"
                isRequired
                validationMessage="Inactive session timeout must be greater than zero"
                isValid={isInactiveSessionTimeoutValid()}
                showValidation={hasSubmitted}
            >
                <input
                    id="identity-provider-inactive-session-timeout"
                    className="form-control form-control-dynamic"
                    value={item.inactiveSessionTimeout ?? ''}
                    onChange={(e) =>
                        updateItem({
                            inactiveSessionTimeout: Number.isNaN(e.target.valueAsNumber)
                                ? null
                                : e.target.valueAsNumber,
                        })
                    }
                    type="number"
                />
                <span className="help-block">
                    Invalidate the users session after X minutes of inactivity
                </span>
            </FormGroup>

            <FormGroup
                label="Active Session Timeout (minutes)"
                htmlFor="identity-provider-active-session-timeout"
                isRequired
                validationMessage="Active session timeout must be greater than zero and greater than or equal to inactive session timeout"
                isValid={isActiveSessionTimeoutValid()}
                showValidation={hasSubmitted}
            >
                <input
                    id="identity-provider-active-session-timeout"
                    className="form-control form-control-dynamic"
                    value={item.activeSessionTimeout ?? ''}
                    onChange={(e) =>
                        updateItem({
                            activeSessionTimeout: Number.isNaN(e.target.valueAsNumber)
                                ? null
                                : e.target.valueAsNumber,
                        })
                    }
                    type="number"
                />
                <span className="help-block">
                    Invalidate the users session after X minutes regardless of activity
                </span>
            </FormGroup>

            <IdentityProviderAttributeMappings
                item={item}
                header="SAML Attribute Name"
                onChange={onAttributeMappingChange}
            />

            <FormGroup label="Comments" htmlFor="identity-provider-comments">
                <textarea
                    id="identity-provider-comments"
                    className="form-control form-control-textarea"
                    value={item.developerSummary ?? ''}
                    onChange={(e) => updateItem({ developerSummary: e.target.value })}
                    placeholder="Comments about this Identity Provider"
                />
            </FormGroup>
        </>
    );
};

export default IdentityProviderSAML;
