import { useRef } from 'react';
import { type ConnectedProps, connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import '../../../../css/tabs.less';
import {
    addNotification,
    notifyError,
    notifySuccess,
} from '../../../js/actions/reduxActions/notification';
import {
    closeTab,
    setTabTitle,
    updateTab,
    updateUrlAndTab,
} from '../../../js/actions/reduxActions/tabs';
import Admin from '../../../js/components/admin';
import FlowEditorLegacy from '../../../js/components/flow/flow-editor';
import { GraphProvider } from '../../../js/components/graph/GraphProvider';
import { ClipBoardProvider } from '../../ClipBoardProvider';
import { SYSTEM_ROLES } from '../../constants';
import { useTabRouting } from '../../hooks';
import type { Tab } from '../../types';
import { getFlag } from '../../utils/flags';
import { useAuth } from '../AuthProvider';
import { DebugConfigProvider } from '../flow/debug/DebugConfigProvider';
import ErrorBoundary from '../generic/ErrorBoundary';
import FlowEditor from '../graphv2/render/FlowEditor';
import FlowEditorSharedWrapper, {
    type FlowEditorSharedData,
} from '../graphv2/render/FlowEditorSharedWrapper';
import FlowEditorStoreProvider, {
    type FlowEditorDispatch,
} from '../graphv2/render/FlowEditorStoreProvider';
import PageEditor from '../page-editor/components/gui/PageEditor';
import Tabs from './Tabs';

type TabContentProps = {
    adminTenantId: string;
    isTenantSelectorOpen: boolean;
} & ConnectedProps<typeof connector>;

const mapStateToProps = ({ tabs }: { tabs: Tab[] }) => ({
    tabs,
});

const connector = connect(mapStateToProps, (dispatch) => ({
    dispatch,
    ...bindActionCreators(
        {
            addNotification,
            updateTab,
            setTabTitle,
            updateUrlAndTab,
            closeTab,
            notifyError,
        },
        dispatch,
    ),
}));

const TabContent = ({
    tabs,
    addNotification,
    updateTab,
    updateUrlAndTab,
    setTabTitle,
    isTenantSelectorOpen,
    notifyError,
    dispatch,
}: TabContentProps) => {
    const sideBarRef = useRef(null);

    const { tenant } = useAuth();

    const role = tenant?.role;
    const environmentsIsOn = tenant?.tenantSettings?.environments;
    const isAdministrator = role === SYSTEM_ROLES.administrator.developerName;

    useTabRouting(role, environmentsIsOn ?? false, isAdministrator, addNotification, dispatch);

    const isGraphV3Enabled = getFlag('GRAPH3');

    const getTabComponent = (tab: Tab) => {
        if (tab) {
            switch (tab.type) {
                case 'flow':
                    return (
                        <ErrorBoundary key={tab.key}>
                            {isGraphV3Enabled ? (
                                <FlowEditorStoreProvider>
                                    {({ dispatch }: FlowEditorDispatch) => (
                                        <FlowEditorSharedWrapper
                                            flowId={tab.elementId}
                                            notifyError={notifyError}
                                        >
                                            {({
                                                releases,
                                                environments,
                                                loadReleases,
                                                isLoadingReleases,
                                            }: FlowEditorSharedData) => (
                                                <FlowEditor
                                                    dispatch={dispatch}
                                                    flowId={tab.elementId}
                                                    tabKey={tab.key}
                                                    setTabTitle={setTabTitle}
                                                    isActive={tab.isActive}
                                                    notifyError={notifyError}
                                                    notifySuccess={notifySuccess}
                                                    addNotification={addNotification}
                                                    releases={releases}
                                                    environments={environments}
                                                    loadReleases={loadReleases}
                                                    isLoadingReleases={isLoadingReleases}
                                                />
                                            )}
                                        </FlowEditorSharedWrapper>
                                    )}
                                </FlowEditorStoreProvider>
                            ) : (
                                <GraphProvider
                                    flowId={tab.elementId}
                                    isActive={tab.isActive}
                                    addNotification={addNotification}
                                    isTenantSelectorOpen={isTenantSelectorOpen}
                                >
                                    <DebugConfigProvider>
                                        <FlowEditorSharedWrapper
                                            flowId={tab.elementId}
                                            notifyError={notifyError}
                                        >
                                            {({
                                                releases,
                                                environments,
                                                loadReleases,
                                                isLoadingReleases,
                                            }: FlowEditorSharedData) => (
                                                <FlowEditorLegacy
                                                    flowId={tab.elementId}
                                                    sideBarRef={sideBarRef}
                                                    tabKey={tab.key}
                                                    setTabTitle={setTabTitle}
                                                    releases={releases}
                                                    environments={environments}
                                                    loadReleases={loadReleases}
                                                    isLoadingReleases={isLoadingReleases}
                                                />
                                            )}
                                        </FlowEditorSharedWrapper>
                                    </DebugConfigProvider>
                                </GraphProvider>
                            )}
                        </ErrorBoundary>
                    );

                case 'newPage':
                case 'page':
                    return (
                        <PageEditor
                            tabConfig={tab}
                            addNotification={addNotification}
                            updateTab={updateTab}
                            updateUrlAndTab={updateUrlAndTab}
                            key={tab.key}
                        />
                    );
                default:
                    return <Admin isActive={tab.isActive} tabType={tab.type} key={tab.key} />;
            }
        }

        return null;
    };

    return (
        <div className="tabs flex-column">
            <ClipBoardProvider>
                <Tabs tabs={tabs} />
                <div className="tab-content-wrapper">{tabs.map(getTabComponent)}</div>
            </ClipBoardProvider>
        </div>
    );
};

export default connector(TabContent);
