import ListenerDetails from '../common/listeners/ListenerDetails';
import MessageActionDetails from '../common/messageActions/MessageActionDetails';
import NavigationOverrideDetails from '../common/navigationOverrides/NavigationOverrideDetails';
import OutcomeDetails from '../common/OutcomeDetails';
import screens from '../common/screens';
import WaitDetails from '../common/wait/WaitDetails';
import { useMapElement } from '../contextProviders/MapElementProvider';
import PageConfiguration from './PageConfiguration';

const PageRouter = () => {
    const {
        currentScreen,
        mapElement,
        selectedOutcomeIndex,
        onReturnToDefaultScreen,
        setSelectedOutcomeIndex,
    } = useMapElement();

    switch (currentScreen) {
        case screens.page:
            return <PageConfiguration />;

        case screens.outcome: {
            const selectedOutcome = mapElement.outcomes?.[selectedOutcomeIndex ?? 0] ?? null;

            return (
                <OutcomeDetails
                    outcome={selectedOutcome}
                    onSave={onReturnToDefaultScreen}
                    onCancel={() => {
                        onReturnToDefaultScreen();
                        setSelectedOutcomeIndex(null);
                    }}
                />
            );
        }

        case screens.messageActions:
            return <MessageActionDetails />;

        case screens.listener:
            return <ListenerDetails />;

        case screens.navigationOverrides:
            return <NavigationOverrideDetails />;

        case screens.wait:
            return <WaitDetails />;

        default:
            return null;
    }
};

export default PageRouter;
