import translations from '../../../translations';
import type { NotifyError } from '../../../types';
import type { View } from '../InsightsDashboard';
import AnomalySummary from './AnomalySummary';
import ErrorsSummary from './ErrorsSummary';
import UsageSummary from './UsageSummary';

interface Props {
    setCurrentView: (currentView: View) => void;
    notifyError: NotifyError;
    screen?: string;
}

const InsightsSummary = ({ setCurrentView, notifyError }: Props) => {
    return (
        <>
            <span className="title-bar">
                <h1>{translations.DASHBOARD_header}</h1>
            </span>
            <div className="insights-content">
                <UsageSummary notifyError={notifyError} setCurrentView={setCurrentView} />
                <ErrorsSummary setCurrentView={setCurrentView} />
                <AnomalySummary setCurrentView={setCurrentView} />
            </div>
        </>
    );
};

export default InsightsSummary;
