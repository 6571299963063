import { FloppyDisk, X } from '@phosphor-icons/react';
import { type ChangeEventHandler, useState } from 'react';
import FormGroup from '../generic/FormGroup';
import Modal from '../generic/modal/GenericModal';
import { REGEX_PLAYER_NAME } from '../../constants';
import translations from '../../translations';
import ButtonDefault from '../buttons/ButtonDefault';
import ButtonPrimary from '../buttons/ButtonPrimary';
import './player-editor.less';
import Select from 'react-select';
import { getSharedStyles } from '../../utils/select';

interface Props {
    show: boolean;
    playerOptions: { value: string; label: string }[];
    onSave: (name: string, selectedPlayer: string) => void;
    onClose: () => void;
    container: HTMLElement | null;
}

const NewPlayerModal = ({ show, playerOptions, onSave, onClose, container }: Props) => {
    const [playerName, setPlayerName] = useState('');
    const [selectedPlayerName, setSelectedPlayerName] = useState('');
    const [isNameValid, setIsNameValid] = useState(true);
    const [isBaseValid, setIsBaseValid] = useState(true);

    const handleSave = () => {
        const _isNameValid = playerName !== '' && isNameValid;
        const _isBaseValid = selectedPlayerName !== '';

        if (!(_isNameValid && _isBaseValid)) {
            setIsNameValid(_isNameValid);
            setIsBaseValid(_isBaseValid);
            return;
        }

        onSave(playerName, selectedPlayerName);

        handleClose();
    };

    const handleClose = () => {
        setPlayerName('');
        setSelectedPlayerName('');
        setIsBaseValid(true);
        setIsNameValid(true);
        onClose();
    };

    const handleNameChange: ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
        const isNameUnique = !playerOptions.some(
            ({ label }) => label.toLowerCase() === value.toLowerCase(),
        );

        if (REGEX_PLAYER_NAME.test(value) && isNameUnique) {
            setIsNameValid(true);
        } else {
            setIsNameValid(false);
        }

        setPlayerName(value);
    };

    const handlePlayerSelectionChange = (
        option: { value: string; label: string | null } | null,
    ) => {
        if (!option?.value) {
            setIsBaseValid(false);
            return;
        }

        setSelectedPlayerName(option.value);
        setIsBaseValid(true);
    };

    const selectedPlayerOption = playerOptions?.find(
        (player) => player.label === selectedPlayerName,
    ) ?? {
        label: translations.PLAYER_new_player_modal_base_select_placeholder,
        value: '',
    };

    const portalTarget = (() => {
        const target = document.querySelector('.active');

        if (target) {
            return target as HTMLElement;
        }

        return null;
    })();

    return (
        <Modal
            onHide={handleClose}
            show={show}
            title={translations.PLAYER_new_player_modal_title}
            container={container}
            bodyClassName="new-player-modal-body"
            renderBody={() => (
                <>
                    <FormGroup
                        label={translations.PLAYER_new_player_modal_name_input_label}
                        htmlFor="player-name-input"
                        isRequired
                        isValid={isNameValid}
                        showValidation={!isNameValid}
                        validationMessage={translations.PLAYER_invalid_player_name_message}
                    >
                        <input
                            id="player-name-input"
                            className="form-control"
                            onChange={handleNameChange}
                            value={playerName}
                        />
                    </FormGroup>
                    <FormGroup
                        label={translations.PLAYER_new_player_modal_base_select_label}
                        htmlFor="base-player-select"
                        isRequired
                        isValid={isBaseValid}
                        showValidation={!isBaseValid}
                        validationMessage={translations.PLAYER_invalid_player_base_message}
                    >
                        <Select
                            inputId="base-player-select"
                            className="base-select"
                            styles={getSharedStyles<{ label: string; value: string }>()}
                            onChange={handlePlayerSelectionChange}
                            value={selectedPlayerOption}
                            options={playerOptions}
                            menuPosition="fixed"
                            menuPortalTarget={(() => {
                                // Can't use the Editor component as a container due to styling issues
                                const target = document.querySelector('.tab-content-wrapper');
                                return target instanceof HTMLElement ? target : null;
                            })()}
                            closeMenuOnScroll={({ target }: { target: HTMLElement | null }) =>
                                target === portalTarget
                            }
                        />
                    </FormGroup>
                </>
            )}
            renderFooter={() => (
                <>
                    <ButtonDefault onClick={handleClose} testId="player-create-modal-cancel-button">
                        <X size={16} weight="bold" className="player-button-icon" />
                        {translations.PLAYER_new_player_modal_cancel_button_label}
                    </ButtonDefault>
                    <ButtonPrimary onClick={handleSave} testId="player-create-modal-save-button">
                        <FloppyDisk size={16} weight="bold" className="player-button-icon" />
                        {translations.PLAYER_new_player_modal_save_button_label}
                    </ButtonPrimary>
                </>
            )}
        />
    );
};

export default NewPlayerModal;
