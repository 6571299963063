import ButtonDanger from '../../../buttons/ButtonDanger';
import FormGroup from '../../../generic/FormGroup';
import Select, { type SingleValue } from 'react-select';
import NavigationUrlInputs from '../../NavigationUrlInputs';
import ButtonDefault from '../../../buttons/ButtonDefault';
import ButtonPrimary from '../../../buttons/ButtonPrimary';
import { getSharedStyles } from '../../../../utils';
import type { MapElement, ValueElementIdAPI } from '../../../../types';
import type { NavigationErrors } from '../utils';
import type { ChangeEvent, MouseEvent } from 'react';

type MapElementTypeOption = {
    label: string;
    value: string;
};

type Props = {
    mapElements: MapElement[];
    isEditing: boolean;
    errors: NavigationErrors;
    label: string | null;
    url: ValueElementIdAPI | null;
    mapElement: string | null;
    isNewItem: boolean;
    onDeleteClick: () => void;
    updateMapElement: (option: SingleValue<MapElementTypeOption>) => void;
    isUrl: boolean;
    urlTarget: string | null;
    onUrlChanged: (value: ValueElementIdAPI | null) => void;
    updateUrlTarget: (e: ChangeEvent<HTMLSelectElement>) => void;
    cancelEdit: () => void;
    saveNavigationItem: (e: MouseEvent<HTMLButtonElement>) => void;
    updateLabel: (e: ChangeEvent<HTMLInputElement>) => void;
    name: string | null;
};

const ConfigureNavigationItem = ({
    mapElements,
    errors,
    isNewItem,
    onDeleteClick,
    isEditing,
    updateMapElement,
    isUrl,
    label,
    url,
    urlTarget,
    onUrlChanged,
    updateUrlTarget,
    cancelEdit,
    saveNavigationItem,
    updateLabel,
    mapElement,
    name,
}: Props) => {
    if (mapElements) {
        const options = mapElements.map((mapElement) => ({
            label: mapElement.developerName,
            value: mapElement.id,
        }));

        options.unshift({
            label: 'URL',
            value: 'URL',
        });

        const deleteButton = !isNewItem && (
            <ButtonDanger onClick={onDeleteClick}>Delete</ButtonDanger>
        );

        if (isEditing) {
            return (
                <div className="navigation-input" key="form">
                    <div className="input-group-wrapper">
                        <FormGroup
                            label="Navigate To "
                            className={`input-group-item${errors.mapElement ? ' has-error' : ''}`}
                            htmlFor="navigate-to"
                        >
                            <Select
                                styles={getSharedStyles()}
                                inputId="navigate-to"
                                options={options}
                                onChange={(val: SingleValue<MapElementTypeOption>) =>
                                    updateMapElement(val)
                                }
                                placeholder="Select a map element or go to a URL"
                                noOptionsMessage={() => 'No results found'}
                                required={true}
                                value={
                                    isUrl
                                        ? { label: 'URL', value: 'URL' }
                                        : mapElement
                                          ? { label: name, value: mapElement }
                                          : null
                                }
                            />
                            {errors.mapElement ? (
                                <div className="help-block error-state">
                                    A map element or URL is required
                                </div>
                            ) : null}
                        </FormGroup>
                        <FormGroup
                            label="Label "
                            className={`input-group-item${errors.label ? ' has-error' : ''}`}
                            htmlFor="navigate-to-label"
                        >
                            <input
                                id="navigate-to-label"
                                data-testid="navigate-to-label"
                                className="form-control"
                                type="text"
                                value={label || ''}
                                onChange={updateLabel}
                                required={true}
                                maxLength={255}
                            />
                            {errors.label ? (
                                <div className="help-block error-state">A label is required</div>
                            ) : null}
                        </FormGroup>
                    </div>
                    {isUrl && (
                        <NavigationUrlInputs
                            hasUrlError={errors.url}
                            url={url}
                            urlTarget={urlTarget}
                            onUrlChanged={onUrlChanged}
                            updateUrlTarget={updateUrlTarget}
                            controlWidth={false}
                        />
                    )}
                    <div className="add-navigation-actions">
                        <ButtonDefault onClick={cancelEdit}>Cancel</ButtonDefault>
                        <ButtonPrimary onClick={saveNavigationItem}>Save</ButtonPrimary>
                        {deleteButton}
                    </div>
                </div>
            );
        }
    }

    return null;
};

export default ConfigureNavigationItem;
