import { useEffect, useState } from 'react';
import { useMapElement } from '../contextProviders/MapElementProvider';
import NameInput from '../common/NameInput';
import translations from '../../../../translations';
import ButtonDefault from '../../../buttons/ButtonDefault';
import ButtonPrimary from '../../../buttons/ButtonPrimary';
import FormGroup from '../../../generic/FormGroup';
import ModalBody from '../../../generic/modal/ModalBody';
import ModalFooter from '../../../generic/modal/ModalFooter';
import { useOpenApi } from '../contextProviders/OpenApiProvider';
import ValueSelectorModal from '../../../values/selector/ValueSelectorModal';
import {
    GetOpenApiServices,
    getOpenApiSchemaInfoFromServiceId,
    getOpenApiSchemaInfoFromValueId,
} from '../../../../sources/openapi';
import { ExLoader } from '@boomi/exosphere';
import OpenApiParameterList from './OpenApiParameterList';
import { DATA_ACTION_VALUE_SELECTION_LABEL } from '../common/constants';

/**
 * The openapi action configuration screen
 */
const OpenApiDetails = () => {
    const [noSchema, setNoSchema] = useState<boolean>(false);
    const [servicesLoaded, setServicesLoaded] = useState<boolean>(false);
    const { mapElement, setConfigTitle, container } = useMapElement();

    const {
        hasSubmitted,
        operationTypes,
        operationDelete,
        operationLoad,
        operationSave,
        operationUpdate,
        onOperationChange,
        openApiActionToEdit,
        setHasSubmitted,
        isValidOpenApiAction,
        onApplyOpenApiAction,
        onReturnToDefaultScreen,
        isActionNameValid,
        onUpdateActionName,
        isValidServiceValue,
        onValueChanged,
        setOpenApiSchemaInfo,
        openApiSchemaInfo,
        onPathChange,
        isValidPath,
        openApiServices,
        onServiceChange,
        deleteType,
        deleteTypeValue,
        deleteTypeConnector,
        onDeleteTypeChange,
        isValidService,
        setOpenApiServices,
    } = useOpenApi();

    const openApiAction = openApiActionToEdit.openApiAction;
    const currentOperation = openApiAction?.operationType;
    const { index } = openApiActionToEdit;
    const valueLabel =
        currentOperation === operationLoad
            ? DATA_ACTION_VALUE_SELECTION_LABEL.LOAD
            : currentOperation === operationSave || currentOperation === operationUpdate
              ? DATA_ACTION_VALUE_SELECTION_LABEL.SAVE
              : currentOperation === operationDelete
                ? DATA_ACTION_VALUE_SELECTION_LABEL.DELETE
                : translations.VALUE;
    const isDeleteOperation = currentOperation === operationDelete;
    const showValuePicker =
        !isDeleteOperation || (isDeleteOperation && deleteType === deleteTypeValue);

    useEffect(() => {
        setConfigTitle(`${translations.OPENAPI_actions_for}${mapElement.developerName ?? ''}`);

        return () => setConfigTitle(null);
    }, [mapElement.developerName, setConfigTitle]);

    useEffect(() => {
        setOpenApiSchemaInfo(undefined);
        setNoSchema(false);
        const getInfo = async () => {
            if (
                (openApiAction?.value ?? null) !== null &&
                (openApiAction?.value?.id ?? null) !== null
            ) {
                const valueId = openApiAction?.value?.id as string;
                const info = await getOpenApiSchemaInfoFromValueId({
                    valueId,
                    operationType: currentOperation ?? operationLoad,
                });
                if (info?.schemaInfo) {
                    setOpenApiSchemaInfo(info.schemaInfo);
                } else {
                    setNoSchema(true);
                }
            }
        };
        getInfo();
    }, [currentOperation, openApiAction?.value, setOpenApiSchemaInfo, operationLoad]);

    useEffect(() => {
        const getServices = async () => {
            if (isDeleteOperation && !servicesLoaded && openApiAction?.serviceId) {
                setOpenApiServices(await GetOpenApiServices());
                setServicesLoaded(true);
                const info = await getOpenApiSchemaInfoFromServiceId({
                    serviceId: openApiAction?.serviceId,
                });
                if (info?.schemaInfo) {
                    setOpenApiSchemaInfo(info.schemaInfo);
                }
            }
        };
        getServices();
    });

    const onSave = () => {
        setHasSubmitted(true);

        if (isValidOpenApiAction() && index !== null) {
            onApplyOpenApiAction(index);
        }
    };

    const renderBody = () => (
        <>
            <NameInput
                isValid={isActionNameValid()}
                showValidation={hasSubmitted}
                id="openapi-name"
                name={openApiAction?.developerName ?? ''}
                onUpdateName={onUpdateActionName}
            />
            <FormGroup label="Operation" htmlFor="operation-select">
                <select
                    id="operation-select"
                    value={currentOperation ?? operationLoad}
                    onChange={onOperationChange}
                    required
                    className="form-control form-control-width"
                >
                    {operationTypes.map((operationType) => (
                        <option key={operationType} value={operationType}>
                            {operationType}
                        </option>
                    ))}
                </select>
            </FormGroup>
            {isDeleteOperation && (
                <FormGroup label={translations.OPENAPI_delete_type} htmlFor="delete-type-select">
                    <select
                        id="delete-type-select"
                        value={deleteType}
                        className="form-control form-control-width"
                        onChange={onDeleteTypeChange}
                    >
                        <option value={deleteTypeValue}>{translations.OPENAPI_value}</option>
                        <option value={deleteTypeConnector}>
                            {translations.OPENAPI_connector}
                        </option>
                    </select>
                </FormGroup>
            )}
            {showValuePicker && (
                <FormGroup
                    label={valueLabel}
                    isRequired
                    isValid={isValidServiceValue()}
                    showValidation={hasSubmitted}
                    validationMessage={translations.MAP_ELEMENT_value_field_validation_message}
                >
                    <ValueSelectorModal
                        value={openApiAction?.value ?? null}
                        onChangeAsValueReference={onValueChanged}
                        includeSystemValues={false}
                        container={container}
                    />
                </FormGroup>
            )}
            {!openApiSchemaInfo && openApiAction?.value && !noSchema && <ExLoader />}
            {noSchema && (
                <span className="help-block error-state">{translations.OPENAPI_no_schema}</span>
            )}
            {isDeleteOperation && deleteType === deleteTypeConnector && (
                <FormGroup
                    label={translations.OPENAPI_connector}
                    htmlFor="service-select"
                    showValidation={hasSubmitted}
                    isRequired
                    isValid={isValidService()}
                    validationMessage={translations.OPENAPI_invalid_connector}
                >
                    <select
                        id="service-select"
                        value={openApiAction?.serviceId ?? undefined}
                        onChange={onServiceChange}
                        required
                        className="form-control form-control-width"
                    >
                        <option value="">{translations.OPENAPI_select_connector}</option>
                        {openApiServices.map((serviceApi) => (
                            <option key={serviceApi.id} value={serviceApi.id}>
                                {serviceApi.developerName}
                            </option>
                        ))}
                    </select>
                </FormGroup>
            )}
            {openApiSchemaInfo && (
                <FormGroup
                    label="Path"
                    htmlFor="path-select"
                    isRequired
                    showValidation={hasSubmitted}
                    isValid={isValidPath()}
                    validationMessage={translations.OPENAPI_invalid_path}
                >
                    <select
                        id="path-select"
                        value={openApiAction?.path ?? undefined}
                        onChange={onPathChange}
                        required
                        className="form-control form-control-width"
                    >
                        <option value="">{translations.OPENAPI_select_path}</option>
                        {openApiSchemaInfo.paths.map((path) => (
                            <option key={path.path} value={path.path}>
                                {path.path}
                            </option>
                        ))}
                    </select>
                </FormGroup>
            )}
            {openApiAction?.parameters && openApiAction?.parameters.length > 0 && (
                <OpenApiParameterList />
            )}
        </>
    );

    const renderFooter = () => [
        <ButtonDefault
            key="cancelButton"
            className="flex-child-right"
            onClick={onReturnToDefaultScreen}
        >
            {translations.GRAPH_config_panel_cancel}
        </ButtonDefault>,
        <ButtonPrimary key="addButton" className="margin-left" onClick={onSave}>
            {translations.GRAPH_config_panel_add}
        </ButtonPrimary>,
    ];

    return (
        <>
            <ModalBody>{renderBody()}</ModalBody>
            <ModalFooter>{renderFooter()}</ModalFooter>
        </>
    );
};

export default OpenApiDetails;
