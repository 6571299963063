import { FLOW_EDITING_TOKEN } from '../constants';
import type {
    DataAction,
    Listener,
    MapElement,
    MessageAction,
    NavigationOverride,
    Vote,
    BuilderWhoAPI,
    ElementAPI,
    ValueElementIdAPI,
    FlowGraphResponse2API,
    Process,
    FlowIdentityProviderAPI,
    OpenApiAction,
    OperationAPI,
} from '../types';
import type { GroupAuthorizationAPI } from '../types/flow';
import { fetchAndParse, CheckStatus } from '../utils/ajax';
import { getTenantId } from '../utils/tenant';

export interface FlowIdAPI {
    id: string;
    versionId: string;
}

export interface FlowGraphRequestAPI extends FlowRequestAPI {
    mapElements: MapElementResponseAPI[];
    groupElements: GroupElementResponseAPI[];
}

export interface FlowGraphResponseAPI extends FlowGraphRequestAPI {
    tenantId: string;
    dateModified: string;
}

export interface GroupAuthorizationLocationAPI {
    developerName: string;
    developerSummary: string;
    valueElementToReferenceId: ValueElementIdAPI;
    attribute: string;
}

export interface FlowRequestAPI {
    editingToken: string | null;
    id: FlowIdAPI;
    developerName: string;
    developerSummary: string;
    startMapElementId: string;
    allowJumping: boolean;
    enableHistoricalNavigation: boolean;
    stateExpirationLength: number;
    idleStateExpirationLength: number;
    authorization: GroupAuthorizationAPI;
    identityProvider: FlowIdentityProviderAPI;
    tags: string[];
}

export interface MapElementRequestAPI extends MapElement {
    operations?: OperationAPI[] | null;
    listeners?: Listener[] | null;
    viewMessageAction?: MessageAction | null;
    messageActions?: MessageAction[] | null;
    processes?: Process[] | null;
    dataActions?: DataAction[] | null;
    navigationOverrides?: NavigationOverride[] | null;
    vote?: Vote | null;
    clearNavigationOverrides?: boolean;
    postUpdateToStream?: boolean;
    userContent?: string;
    userContentDateModified?: string | null;
    statusMessage?: string;
    postUpdateMessage?: string;
    notAuthorizedMessage?: string;
    postUpdateWhenType?: string | null;
    updateByName?: boolean;
    title?: string | null;
}

export interface GroupElementAPI extends ElementAPI {
    groupElementId?: string | null;
    x: number;
    y: number;
    height: number;
    width: number;
    authorization: GroupAuthorizationAPI | null;
    isOpen: boolean;
    identityProvider: FlowIdentityProviderAPI | null;
}

export interface GroupElementRequestAPI extends GroupElementAPI {
    updateByName: boolean;
}

export interface FlowGraphElementIDRequestAPI {
    mapElementIds: string[];
    groupElementIds: string[];
}

export interface FlowGraphElementRequestAPI {
    mapElements: MapElementRequestAPI[];
    groupElements: GroupElementRequestAPI[];
}

export interface MapElementResponseAPI extends MapElementRequestAPI {
    dateCreated: string;
    dateModified: string;
    whoCreated: BuilderWhoAPI | null;
    whoModified: BuilderWhoAPI;
    whoOwner: BuilderWhoAPI;
    operations: OperationAPI[] | null;
    listeners: Listener[] | null;
    viewMessageAction: MessageAction | null;
    processes: Process[] | null;
    messageActions: MessageAction[] | null;
    dataActions: DataAction[] | null;
    navigationOverrides: NavigationOverride[] | null;
    vote: Vote | null;
    openApiActions: OpenApiAction[] | null;
}

export interface GroupElementResponseAPI extends GroupElementRequestAPI {
    dateCreated: string;
    dateModified: string;
    whoCreated: BuilderWhoAPI;
    whoModified: BuilderWhoAPI;
    whoOwner: BuilderWhoAPI;
}

export const getFlowGraph = async (id: string) =>
    fetchAndParse<FlowGraphResponseAPI>({
        url: `/api/draw/1/graph/flow/${id}`,
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const getFlowGraph2 = async (id: string) =>
    fetchAndParse<FlowGraphResponse2API>({
        url: `/api/draw/2/graph/flow/${id}`,
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const saveFlowGraph = async (graph: FlowGraphRequestAPI) =>
    fetchAndParse<FlowGraphResponseAPI>({
        url: '/api/draw/1/graph/flow',
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ManyWhoTenant: getTenantId(),
        },
        body: graph,
    });

export const saveFlowGraph2 = async (graph: FlowGraphResponse2API) =>
    fetch('/api/draw/2/graph/flow', {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
            ManyWhoTenant: getTenantId(),
        },
        body: JSON.stringify(graph),
    }).then(CheckStatus);

export const getMapElements = async (flowId: string) =>
    fetchAndParse<MapElementResponseAPI[]>({
        url: `/api/draw/1/flow/${flowId}/${FLOW_EDITING_TOKEN}/element/map/`,
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const getGroupElements = async (flowId: string) =>
    fetchAndParse<GroupElementResponseAPI[]>({
        url: `/api/draw/1/flow/${flowId}/${FLOW_EDITING_TOKEN}/element/group/`,
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const getMapElement = async (id: string, flowId: string) =>
    fetchAndParse<MapElementResponseAPI>({
        url: `/api/draw/1/flow/${flowId}/${FLOW_EDITING_TOKEN}/element/map/${id}`,
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const getGroupElement = async (id: string, flowId: string) =>
    fetchAndParse<GroupElementResponseAPI>({
        url: `/api/draw/1/flow/${flowId}/${FLOW_EDITING_TOKEN}/element/group/${id}`,
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const saveMapElement = async (request: MapElementRequestAPI, flowId: string) =>
    fetchAndParse<MapElementResponseAPI>({
        url: `/api/draw/1/flow/${flowId}/${FLOW_EDITING_TOKEN}/element/map`,
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ManyWhoTenant: getTenantId(),
        },
        body: request,
    });

export const saveGroupElement = async (request: GroupElementRequestAPI, flowId: string) =>
    fetchAndParse<GroupElementResponseAPI>({
        url: `/api/draw/1/flow/${flowId}/${FLOW_EDITING_TOKEN}/element/group`,
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ManyWhoTenant: getTenantId(),
        },
        body: request,
    });

export const deleteGroupElement = async (id: string, flowId: string) =>
    fetchAndParse<void>({
        url: `/api/draw/1/flow/${flowId}/${FLOW_EDITING_TOKEN}/element/group/${id}`,
        method: 'delete',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const deleteMapElement = async (id: string, flowId: string) =>
    fetchAndParse<void>({
        url: `/api/draw/1/flow/${flowId}/${FLOW_EDITING_TOKEN}/element/map/${id}`,
        method: 'delete',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const deleteElements = async (
    flowId: string,
    graphElementRequest: FlowGraphElementIDRequestAPI,
) =>
    fetchAndParse<void>({
        url: `/api/draw/2/graph/flow/${flowId}/elements/delete`,
        method: 'delete',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
        body: graphElementRequest,
    });

export const getFullGraphElements = async (
    flowId: string,
    graphElementRequest: FlowGraphElementIDRequestAPI,
) =>
    fetchAndParse<FlowGraphResponseAPI>({
        url: `/api/draw/1/graph/flow/${flowId}/elements/load`,
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ManyWhoTenant: getTenantId(),
        },
        body: graphElementRequest,
    });

export const saveFullGraphElements = async (
    flowId: string,
    graphElementRequest: FlowGraphElementRequestAPI,
) =>
    fetchAndParse<void>({
        url: `/api/draw/1/graph/flow/${flowId}/elements/save`,
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ManyWhoTenant: getTenantId(),
        },
        body: graphElementRequest,
    });
