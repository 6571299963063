import { Trash } from '@phosphor-icons/react';
import { useEffect } from 'react';
import translations from '../../../ts/translations';
import type { OrganizationInvite } from '../../sources/organization';
import Table from '../generic/Table';
import { useOrganizationInvite } from './OrganizationInviteProvider';
import { formatTenantName } from '../../utils';

type InviteListProps = {
    caption: string;
    invitations: OrganizationInvite[];
    organizationId: string;
    loadInvites: () => Promise<void>;
    onCancel: (id: string) => void;
    onAcknowledge: (id: string) => void;
};

const dateTimerFormatter = new Intl.DateTimeFormat(undefined, {
    dateStyle: 'medium',
    timeStyle: 'short',
});

const InviteList = ({
    caption,
    invitations,
    organizationId,
    loadInvites,
    onCancel,
    onAcknowledge,
}: InviteListProps) => {
    const { isLoading } = useOrganizationInvite();

    useEffect(() => {
        loadInvites();
    }, [loadInvites]);

    const pendingInvites = invitations.filter(
        ({ status, organization: { id } }) =>
            (status === 'invited' || status === 'rejected') && id === organizationId,
    );

    const getInviteId = (invite: OrganizationInvite) => {
        return invite.type === 'user' ? invite.user.id : invite.tenant.id;
    };

    const handleRemove = (invite: OrganizationInvite) => {
        const id = getInviteId(invite);

        if (invite.status === 'rejected') {
            onAcknowledge(id);
        } else {
            onCancel(id);
        }
    };

    return (
        <Table
            isLoading={isLoading}
            caption={caption}
            items={pendingInvites}
            pagination={true}
            wrapperClassName="margin-top pending-user-invite-list"
            columns={[
                {
                    renderHeader: () => translations.COMMON_TABLE_user,
                    renderCell: ({ item }) => {
                        const content =
                            item.type === 'user'
                                ? item.user.email
                                : formatTenantName(item.tenant.developerName);

                        return (
                            <span title={`${translations.COMMON_TABLE_id}: ${getInviteId(item)}`}>
                                {content}
                            </span>
                        );
                    },
                },
                {
                    renderHeader: () => translations.COMMON_TABLE_status,
                    renderCell: ({ item: { status } }) => (
                        <span className="capitalize">{status}</span>
                    ),
                    size: '20%',
                },
                {
                    renderHeader: () => translations.COMMON_TABLE_date_sent,
                    renderCell: ({ item: { invitedAt } }) =>
                        dateTimerFormatter.format(new Date(invitedAt)),
                    size: '11rem',
                },
                {
                    renderHeader: () => translations.COMMON_TABLE_actions,
                    renderCell: ({ item }) => (
                        <div className="action-btn-wrapper">
                            <button
                                title={translations.FORG_invite_list_remove_invitation_button_label}
                                className="table-icon table-icon-delete"
                                aria-label={
                                    translations.FORG_invite_list_remove_invitation_button_label
                                }
                                onClick={() => handleRemove(item)}
                                type="button"
                            >
                                <Trash />
                            </button>
                        </div>
                    ),
                    size: '5rem',
                },
            ]}
        />
    );
};

export default InviteList;
