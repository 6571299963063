import { useCallback, useEffect, useState } from 'react';
import type { NotifyError, ServiceInvokerRequestAPI } from '../../../types';
import type { StateResponseAPI } from '../../../types/states';
import { View, type SelectedFlow } from '../InsightsDashboard';
import translations from '../../../translations';
import { formatJSONContent, stringReplace } from '../../../utils';
import Table, { type TableColumnList } from '../../generic/Table';
import {
    getServiceInvokerRequest,
    getServiceInvokerRequestsByState,
} from '../../../sources/serviceInvoker';
import { useAuth } from '../../AuthProvider';
import { BadgeShape, ExBadge } from '@boomi/exosphere';
import RootFaultsInsights from './RootFaultsInsights';
import GenericModal from '../../generic/modal/GenericModal';
import SyntaxHighlighter from '../../generic/SyntaxHighlighter';

const renderStatus = (attributes: Record<string, unknown>) => {
    if ((attributes['numberOfFailures'] as number) > 0) {
        return (
            <ExBadge shape={BadgeShape.ROUND} color="red">
                {translations.DASHBOARD_failed}
            </ExBadge>
        );
    }
    return (
        <ExBadge shape={BadgeShape.ROUND} color="green">
            {translations.DASHBOARD_ok}
        </ExBadge>
    );
};

interface Props {
    selectedFlow: SelectedFlow;
    setSelectedFlow: (selectedFlow: SelectedFlow) => void;
    stateDetails: StateResponseAPI | null;
    setStateDetails: (stateDetails: StateResponseAPI | null) => void;
    setCurrentView: (view: View) => void;
    notifyError: NotifyError;
    screen?: string;
}

const ConnectorInvokerRequestInsights = ({
    selectedFlow,
    setSelectedFlow,
    stateDetails,
    setStateDetails,
    setCurrentView,
    notifyError,
}: Props) => {
    const [requests, setRequests] = useState<ServiceInvokerRequestAPI[]>([]);
    const [content, setContent] = useState<string>('');
    const [showContent, setShowContent] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    // Filters and pagination
    const [page, setPage] = useState(1);
    const [pageSize] = useState(50);
    const [total, setTotal] = useState(0);

    const { tenant } = useAuth();

    const refreshRequests = useCallback(
        (page = 1) => {
            if (!tenant || !stateDetails?.id) {
                return;
            }

            setPage(page);
            setIsLoading(true);

            getServiceInvokerRequestsByState({
                tenantId: tenant.id,
                stateId: stateDetails.id,
                page,
                pageSize,
            })
                .then((response) => {
                    setRequests(response.items);
                    setTotal(response._meta.total);
                })
                .catch(notifyError)
                .finally(() => setIsLoading(false));
        },
        [notifyError, pageSize, stateDetails?.id, tenant],
    );

    const onShowFailures = (serviceInvokerRequest: ServiceInvokerRequestAPI) => {
        if (!tenant) {
            return;
        }

        getServiceInvokerRequest({
            tenantId: tenant.id,
            requestId: serviceInvokerRequest.id,
        })
            .then((response) => {
                const failures = response.failures;
                setContent(failures ? JSON.stringify(failures) : '');
                setShowContent(true);
            })
            .catch(notifyError)
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        refreshRequests();
    }, [refreshRequests]);

    const columns: TableColumnList<ServiceInvokerRequestAPI> = [
        {
            renderHeader: () => translations.COMMON_TABLE_occurred_at_utc,
            renderCell: ({ item }) =>
                new Date(item.createdAt).toLocaleString(undefined, {
                    dateStyle: 'medium',
                    timeStyle: 'medium',
                }),
            size: '11rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_uri,
            renderCell: ({ item }) => item.uri,
        },
        {
            renderHeader: () => translations.COMMON_TABLE_method,
            renderCell: ({ item }) => item.method,
            size: '100px',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_status,
            renderCell: ({ item }) => renderStatus(item.attributes),
            size: '100px',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ item }) => (
                <>
                    <button
                        className="link-emulate"
                        onClick={() => onShowFailures(item)}
                        type="button"
                    >
                        {translations.DASHBOARD_failures}
                    </button>
                </>
            ),
            size: '10rem',
        },
    ];

    return (
        <>
            <span className="title-bar">
                <h1>
                    <button
                        className="link-emulate"
                        onClick={() => {
                            setCurrentView(View.summary);
                            setStateDetails(null);
                            setSelectedFlow({ id: '', name: '' });
                        }}
                        type="button"
                    >
                        {translations.DASHBOARD_header}
                    </button>
                    {' > '}
                    <button
                        className="link-emulate"
                        onClick={() => {
                            setCurrentView(View.states);
                            setStateDetails(null);
                        }}
                        type="button"
                    >
                        {stringReplace(translations.DASHBOARD_states_header, selectedFlow.name)}
                    </button>
                    {' > '}
                    {translations.DASHBOARD_connector_request_header}
                </h1>
            </span>
            <h2>{translations.DASHBOARD_connect_log_events}</h2>
            <Table
                columns={columns}
                items={requests}
                isLoading={isLoading}
                pagination={{
                    page,
                    total,
                    pageSize,
                    changePage: (nextPage) => refreshRequests(nextPage),
                }}
                rowClassName={() => 'generic-row generic-row-tall'}
            />
            <h2>{translations.DASHBOARD_root_faults_header}</h2>
            {stateDetails && <RootFaultsInsights selectedState={stateDetails} />}
            <GenericModal
                className="metadata-editor"
                show={showContent}
                onHide={() => setShowContent(false)}
                title={translations.DASHBOARD_failures}
                renderBody={() => (
                    <SyntaxHighlighter content={formatJSONContent(content ?? '')} language="json" />
                )}
                renderFooter={() => (
                    <div className="flex">
                        <div className="flex-child-right">
                            <button
                                className="btn btn-sm btn-default"
                                onClick={() => setShowContent(false)}
                                type="button"
                            >
                                {translations.COMMON_close}
                            </button>
                        </div>
                    </div>
                )}
            />
        </>
    );
};

export default ConnectorInvokerRequestInsights;
