import {
    HttpTransportType,
    type HubConnection,
    HubConnectionBuilder,
    HubConnectionState,
    LogLevel,
} from '@microsoft/signalr';
import { type Operation, applyPatch, compare } from 'fast-json-patch';
import { assocPath, dissocPath, map, omit } from 'ramda';
import {
    type ReactNode,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useReducer,
    useRef,
} from 'react';
import { ENGINE_API_URL } from '../constants';

export interface User {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
}
export interface Cursor {
    x: number;
    y: number;
    id: string;
}

interface RealtimeItem {
    users: string[];
    cursors: Record<string, Cursor>;
    configuration: unknown;
    moveToCursor: string | null;
}

enum ItemType {
    Flow = 0,
    MapElement = 1,
    GroupElement = 2,
}

enum ListenEvents {
    TENANT = 'Tenant',
    JOINED = 'Joined',
    LEFT = 'Left',
    ITEM_OPENED = 'ItemOpened',
    ITEM_CONFIGURATION = 'ItemConfiguration',
    ITEM_CHANGED = 'ItemChanged',
    ITEM_CLOSED = 'ItemClosed',
    CURSOR_CHANGED = 'CursorChanged',
    MAP_ELEMENT_MOVED = 'MapElementMoved',
}

type AllowedEvents =
    | 'UsersChanged'
    | 'ItemOpened'
    | 'ItemClosed'
    | 'ItemChanged'
    | 'ItemFromCache'
    | 'CursorMoved'
    | 'CursorsChanged'
    | 'MapElementOpened'
    | 'MapElementClosed'
    | 'MapElementChanged'
    | 'GraphChanged';

interface CollaborationProviderProps {
    tenantId: string;
    children: ReactNode;
}

interface CollaborationProviderContext {
    itemOpened: (
        id: string,
        flowId: string | undefined,
        itemType: ItemType,
        configuration: unknown,
        sync?: boolean,
    ) => Promise<void>;
    itemChanged: (id: string, itemType: ItemType, item: unknown, sync?: boolean) => Promise<void>;
    itemClosed: (id: string, itemType: ItemType, sync?: boolean) => Promise<void>;
    cursorMoved: (id: string, x: number, y: number) => Promise<void>;
    getItem: (id: string) => unknown;
    moveToCursor: (id: string, userId: string) => void;
    // biome-ignore lint/suspicious/noExplicitAny: Unsure of better solution
    invoke: (event: AllowedEvents, ...args: any[]) => void;
    // biome-ignore lint/suspicious/noExplicitAny: Unsure of better solution
    subscribe: (event: AllowedEvents, callback: (...args: any[]) => void) => void;
    // biome-ignore lint/suspicious/noExplicitAny: Unsure of better solution
    unsubscribe: (event: AllowedEvents, callback: (...args: any[]) => void) => void;
    users: Record<string, User>;
    items: Record<string, RealtimeItem>;
}

interface State {
    users: Record<string, User>;
    items: Record<string, RealtimeItem>;
}

const Context = createContext<CollaborationProviderContext | undefined>(undefined);

type Action =
    | { type: 'tenant'; tenant: State }
    | { type: 'joined'; users: Record<string, User> }
    | { type: 'left'; userId: string }
    | { type: 'itemOpened'; id: string; item: RealtimeItem }
    | { type: 'itemConfiguration'; id: string; configuration: unknown }
    | { type: 'itemClosed'; id: string; userId: string }
    | { type: 'itemChanged'; id: string; patch: Operation[] }
    | { type: 'cursorChanged'; id: string; cursor: Cursor }
    | { type: 'moveToCursor'; id: string; userId: string };

const initialState = {
    users: {},
    items: {},
};

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'tenant':
            return action.tenant;

        case 'joined':
            return {
                ...state,
                users: action.users,
            };

        case 'left':
            return {
                ...state,
                users: omit([action.userId], state.users),
                items: map((item) => {
                    return {
                        ...item,
                        cursors: dissocPath([action.userId], item.cursors),
                        users: (item.users || []).filter((user) => user !== action.userId),
                    };
                }, state.items),
            };

        case 'itemOpened': {
            let newState = assocPath(['items', action.id, 'users'], action.item.users || [], state);
            newState = assocPath(['items', action.id, 'cursors'], action.item.cursors, newState);
            return newState;
        }

        case 'itemConfiguration':
            return assocPath(['items', action.id, 'configuration'], action.configuration, state);

        case 'itemChanged':
            return assocPath(
                ['items', action.id, 'configuration'],
                applyPatch(state.items?.[action.id]?.configuration, action.patch, false, false)
                    .newDocument,
                state,
            );

        case 'itemClosed': {
            let newState = assocPath(
                ['items', action.id, 'users'],
                state.items?.[action.id]?.users?.filter((user) => user !== action.userId) || [],
                state,
            );
            newState = dissocPath(['items', action.id, 'cursors', action.userId], newState);

            if (newState.items[action.id]?.users.length === 0) {
                delete newState.items[action.id];
            }

            return newState;
        }

        case 'cursorChanged':
            return assocPath(
                ['items', action.id, 'cursors', action.cursor.id],
                action.cursor,
                state,
            );

        case 'moveToCursor':
            return assocPath(['items', action.id, 'moveToCursor'], action.userId, state);
    }
};

const CollaborationProvider = ({ tenantId, children }: CollaborationProviderProps) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const connection = useRef<HubConnection | null>(null);

    useEffect(() => {
        const onTenant = (tenant: State) => dispatch({ type: 'tenant', tenant });
        const onJoined = (users: Record<string, User>) => dispatch({ type: 'joined', users });
        const onLeft = (userId: string) => dispatch({ type: 'left', userId });
        const onCursorChanged = (id: string, cursor: Cursor) =>
            dispatch({ type: 'cursorChanged', id, cursor });
        const onItemOpened = (id: string, item: RealtimeItem) =>
            dispatch({ type: 'itemOpened', id, item });
        const onItemConfiguration = (id: string, configuration: unknown) =>
            dispatch({ type: 'itemConfiguration', id, configuration });
        const onItemChanged = (id: string, patch: Operation[]) =>
            dispatch({ type: 'itemChanged', id, patch });
        const onItemClosed = (id: string, userId: string) =>
            dispatch({ type: 'itemClosed', id, userId });

        connection.current = new HubConnectionBuilder()
            .withUrl(`${ENGINE_API_URL}/collaboration/designtime?tenantId=${tenantId}`, {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets,
            })
            .configureLogging(LogLevel.Information)
            .build();

        connection.current.on(ListenEvents.TENANT, onTenant);
        connection.current.on(ListenEvents.JOINED, onJoined);
        connection.current.on(ListenEvents.LEFT, onLeft);
        connection.current.on(ListenEvents.ITEM_OPENED, onItemOpened);
        connection.current.on(ListenEvents.ITEM_CONFIGURATION, onItemConfiguration);
        connection.current.on(ListenEvents.ITEM_CHANGED, onItemChanged);
        connection.current.on(ListenEvents.ITEM_CLOSED, onItemClosed);
        connection.current.on(ListenEvents.CURSOR_CHANGED, onCursorChanged);

        let wasStopped = false;

        const startConnection = async () => {
            try {
                await connection.current?.start();
            } catch {
                // Effect has been cleaned-up
                if (wasStopped) {
                    return;
                }
            }
        };

        const stopConnection = async () => {
            try {
                wasStopped = true;
                await connection.current?.stop();
            } catch {
                // Silently eat the error
            }
        };

        startConnection();

        return () => {
            stopConnection();
        };
    }, [tenantId]);

    const subscribe = useCallback(
        (event: AllowedEvents, callback: (...args: unknown[]) => void) => {
            connection.current?.on(event, callback);
        },
        [],
    );

    const unsubscribe = useCallback(
        (event: AllowedEvents, callback: (...args: unknown[]) => void) => {
            // off is sometimes undefined (why?)
            connection.current?.off?.(event, callback);
        },
        [],
    );

    const invoke = useCallback(async (event: AllowedEvents, ...args: unknown[]) => {
        if (connection.current?.state !== HubConnectionState.Connected) {
            return;
        }

        await connection.current?.invoke(event, ...args);
    }, []);

    const itemChanged = useCallback(
        async (id: string, itemType: ItemType, item: unknown, sync = true) => {
            const patch = compare(
                // biome-ignore lint/complexity/noBannedTypes: Object | any is the type required by compare. Dedicated refactor required.
                // biome-ignore lint/suspicious/noExplicitAny: Object | any is the type required by compare. Dedicated refactor required.
                state.items?.[id]?.configuration as Object | any[],
                // biome-ignore lint/complexity/noBannedTypes: Object | any is the type required by compare. Dedicated refactor required.
                // biome-ignore lint/suspicious/noExplicitAny: Object | any is the type required by compare. Dedicated refactor required.
                item as Object | any[],
            );
            dispatch({ type: 'itemChanged', id, patch });

            if (sync) {
                await invoke('ItemChanged', id, itemType, patch);
            }
        },
        [state.items, invoke],
    );

    const itemOpened = useCallback(
        async (
            id: string,
            flowId: string | undefined,
            itemType: ItemType,
            configuration: unknown,
            sync = true,
        ) => {
            dispatch({ type: 'itemConfiguration', id, configuration });

            if (sync) {
                for (let retry = 0; retry < 10; retry++) {
                    if (connection.current?.state === HubConnectionState.Connected) {
                        await invoke('ItemOpened', id, flowId, itemType);
                        break;
                    }

                    await new Promise((resolve) => setTimeout(resolve, 500));
                }
            }
        },
        [invoke],
    );

    const itemClosed = useCallback(
        async (id: string, itemType: ItemType, sync = true) => {
            if (sync) {
                await invoke('ItemClosed', id, itemType);
            }
        },
        [invoke],
    );

    const cursorMoved = useCallback(
        async (id: string, x: number, y: number) => {
            await invoke('CursorMoved', id, Math.floor(x), Math.floor(y));
        },
        [invoke],
    );

    const getItem = useCallback(
        (id: string) => {
            return state.items?.[id]?.configuration;
        },
        [state.items],
    );

    const moveToCursor = useCallback((id: string, userId: string) => {
        dispatch({ type: 'moveToCursor', id, userId });
    }, []);

    const contextValue: CollaborationProviderContext = {
        itemChanged,
        itemOpened,
        itemClosed,
        cursorMoved,
        getItem,
        moveToCursor,
        subscribe,
        unsubscribe,
        invoke,
        ...state,
    };

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

const useCollaboration = (): CollaborationProviderContext => {
    const context = useContext(Context);

    if (context === undefined) {
        throw new Error('useCollaboration must be used within a CollaborationProvider');
    }

    return context;
};

export { CollaborationProvider, useCollaboration };
