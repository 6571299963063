import { useCallback, useEffect, useState } from 'react';
import translations from '../../../translations';
import { View, type SelectedFlow } from '../InsightsDashboard';
import { exportToCsv, fetchAllPaged, stringReplace } from '../../../utils';
import { getStatesByFlow } from '../../../sources/dashboard';
import { getFromDate, insightDateFilterItems } from '../../../utils/dashboard';
import { STATE_STATUS } from '../../dashboard/constants';
import InsightsDateFilter from '../InsightsDateFilter';
import type { NotifyError } from '../../../types';
import type { TableColumnList } from '../../generic/Table';
import Table from '../../generic/Table';
import CopyableText from '../../generic/CopyableText';
import type { StateResponseAPI } from '../../../types/states';
import classnames from 'classnames';
import { formatDistanceStrict } from 'date-fns';
import { useInsights } from '../InsightsProvider';
import ButtonDefault from '../../buttons/ButtonDefault';
import { DownloadSimple } from '@phosphor-icons/react';
import { curry, pipe } from 'ramda';
import Loader from '../../loader/Loader';

type StateStatus = 'in_progress' | 'done' | 'expired' | 'errored';

export const getStatusText = (status: StateStatus | null, item: StateResponseAPI) => {
    const { isDone, isExpired } = item;

    switch (status) {
        case 'done':
            return 'Done';

        case 'in_progress':
            return 'In Progress';

        case 'expired':
            return 'Expired';

        case 'errored':
            return isDone ? 'Done' : isExpired ? 'Expired' : 'In Progress';

        // All
        default:
            return isDone ? 'Done' : 'In Progress';
    }
};

export const showErrorFlag = (status: StateStatus | null, item: StateResponseAPI) => {
    if (!item.hasRootFaults || status === 'errored') {
        return false;
    }

    return true;
};

export const showExpiredFlag = (status: StateStatus | null, item: StateResponseAPI) => {
    if (!item.isExpired || status === 'expired') {
        return false;
    }

    return true;
};

export const statusClassName = (item: StateResponseAPI) =>
    classnames({
        'dashboard-status-column': true,
        'status-errored': item.hasRootFaults,
        'status-expired': item.isExpired && !item.hasRootFaults,
        'status-done': item.isDone && !item.hasRootFaults && !item.isExpired,
    });

export const formatStateDataToCsv = (states: StateResponseAPI[]) => {
    const headerRow = [
        'Done',
        'Expired',
        'Has Root Faults',
        'Current Step',
        'User',
        'Date Created',
        'Last Action On',
        'Join Uri',
        'State ID',
    ];

    const bodyRows = states.map((state) => [
        state.isDone,
        state.isExpired,
        state.hasRootFaults,
        state.currentMapElementDeveloperName,
        state.isMcr ? '' : state.currentRunningUserDisplayName,
        state.dateCreated,
        state.dateModified,
        state.joinUri,
        state.id,
    ]);

    const allRows = [headerRow, ...bodyRows];

    return allRows;
};

interface Props {
    selectedFlow: SelectedFlow;
    setCurrentView: (view: View) => void;
    notifyError: NotifyError;
    setStateDetails: (stateDetails: StateResponseAPI) => void;
    screen?: string;
}

const StatesInsights = ({ selectedFlow, setCurrentView, notifyError, setStateDetails }: Props) => {
    const [flowStates, setFlowStates] = useState<StateResponseAPI[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isFetchingAllStates, setIsFetchingAllStates] = useState(false);

    // Filters and pagination
    const [statusFilter, setStatusFilter] = useState<StateStatus>('in_progress');
    const [page, setPage] = useState(1);
    const [pageSize] = useState(50);
    const [total, setTotal] = useState(0);

    const { usageDateFilter, setUsageDateFilter } = useInsights();

    const fetchData = useCallback(
        (page = 1) => {
            setIsLoading(true);
            const toDate = new Date();
            const fromDate = getFromDate(usageDateFilter, toDate);

            getStatesByFlow(selectedFlow.id, {
                status: statusFilter,
                from: fromDate.toISOString(),
                to: toDate.toISOString(),
                pageSize,
                page,
                orderDirection: 'ASC',
                orderBy: 'monthly',
            })
                .then((response) => {
                    setTotal(response._meta.total);
                    setFlowStates(response.items);
                    setPage(page);
                })
                .catch(notifyError)
                .finally(() => setIsLoading(false));
        },
        [selectedFlow.id, notifyError, pageSize, statusFilter, usageDateFilter],
    );

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const exportResultsToCSV = async () => {
        setIsFetchingAllStates(true);

        const exportToCsvCurried = curry(exportToCsv);
        const toDate = new Date();
        const fromDate = getFromDate(usageDateFilter, toDate);

        const states = await fetchAllPaged(
            (_, pageSize) =>
                getStatesByFlow(selectedFlow.id, {
                    status: statusFilter,
                    from: fromDate.toISOString(),
                    to: toDate.toISOString(),
                    pageSize,
                    page: 1,
                    orderDirection: 'ASC',
                    orderBy: 'monthly',
                }),
            1000,
        );

        pipe(formatStateDataToCsv, exportToCsvCurried('states.csv'))(states);

        setIsFetchingAllStates(false);
    };

    const columns: TableColumnList<StateResponseAPI> = [
        {
            renderHeader: () => translations.COMMON_TABLE_status,
            renderCell: ({ item }) => {
                const text = getStatusText(statusFilter, item);
                const showError = showErrorFlag(statusFilter, item);
                const showExpired = showExpiredFlag(statusFilter, item);
                const className = statusClassName(item);

                return (
                    <span className={className}>
                        {text}
                        {showExpired && (
                            <span
                                className="glyphicon glyphicon-time"
                                title={translations.DASHBOARD_expired}
                            />
                        )}
                        {showError && (
                            <span
                                className="glyphicon glyphicon-exclamation-sign status-errored"
                                title={translations.DASHBOARD_root_faults_header}
                            />
                        )}
                    </span>
                );
            },
            size: '10rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_current_step,
            renderCell: ({ item }) => item.currentMapElementDeveloperName,
            size: '11rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_user,
            renderCell: ({ item }) => (item.isMcr ? '' : item.currentRunningUserDisplayName),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_elapsed_time,
            renderCell: ({ item }) => formatDistanceStrict(new Date(item.dateCreated), new Date()),
            size: '6rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_created_at_utc,
            renderCell: ({ item }) =>
                new Date(item.dateCreated).toLocaleString(undefined, {
                    dateStyle: 'medium',
                    timeStyle: 'medium',
                }),
            size: '11rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_last_action_utc,
            renderCell: ({ item }) =>
                new Date(item.dateModified).toLocaleString(undefined, {
                    dateStyle: 'medium',
                    timeStyle: 'medium',
                }),
            size: '11rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_join_uri,
            renderCell: ({ item }) => <a href={item.joinUri}>{item.joinUri}</a>,
        },
        {
            renderHeader: () => translations.COMMON_TABLE_state_id,
            renderCell: ({ item }) => <CopyableText copyableText={item.id} hasCopyButton={true} />,
        },
        {
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ item }) => (
                <button
                    className="link-emulate"
                    onClick={() => {
                        setStateDetails(item);
                        setCurrentView(View.connectorInvokerRequests);
                    }}
                    hidden={item?.isMcr}
                    type="button"
                >
                    {translations.DASHBOARD_log}
                </button>
            ),
            size: '5rem',
        },
    ];

    return (
        <>
            <span className="title-bar insights-states-header">
                <h1>
                    <button
                        className="link-emulate"
                        onClick={() => setCurrentView(View.usage)}
                        type="button"
                    >
                        {translations.DASHBOARD_header}
                    </button>
                    {' > '}
                    {stringReplace(translations.DASHBOARD_states_header, selectedFlow.name)}
                </h1>
                <CopyableText
                    textClassName="flow-id"
                    copyableText={selectedFlow.id}
                    hasCopyButton={true}
                />
            </span>
            <div className="insights-control-bar margin-bottom-large">
                <div className="inights-filter-bar-control flex gap-small">
                    <select
                        className="form-control form-select"
                        value={statusFilter}
                        onChange={(e) => setStatusFilter(e.target.value as StateStatus)}
                    >
                        <option value={STATE_STATUS.inProgress}>
                            {translations.DASHBOARD_in_progress}
                        </option>
                        <option value={STATE_STATUS.done}>{translations.DASHBOARD_done}</option>
                        <option value={STATE_STATUS.errored}>
                            {translations.DASHBOARD_errored}
                        </option>
                        <option value={STATE_STATUS.expired}>
                            {translations.DASHBOARD_expired}
                        </option>
                        <option value="all">{translations.DASHBOARD_all}</option>
                    </select>
                    <div className="form-group export-csv">
                        <ButtonDefault
                            title={translations.AUDIT_FILTER_download_csv}
                            className="export"
                            disabled={flowStates.length === 0}
                            onClick={exportResultsToCSV}
                        >
                            <DownloadSimple />
                        </ButtonDefault>
                    </div>
                </div>
                <InsightsDateFilter
                    selectedDate={usageDateFilter}
                    setSelectedDate={setUsageDateFilter}
                    dateFilterItems={insightDateFilterItems}
                />
            </div>
            <Table
                columns={columns}
                items={flowStates}
                isLoading={isLoading}
                pagination={{
                    page,
                    total: total,
                    pageSize,
                    changePage: (page) => fetchData(page),
                }}
            />
            {isFetchingAllStates && <Loader message={translations.DASHBOARD_downloading_states} />}
        </>
    );
};

export default StatesInsights;
