import type { ComponentProps } from 'react';
import '../../../../css/organization-invite-form.less';
import { GUID_REQUIRED_LENGTH } from '../../constants';
import translations from '../../translations';
import { getEmailValidityMessage, getIdValidityMessage } from '../../utils/validation';
import FormGroup from '../generic/FormGroup';
import InviteModal from './InviteModal';
import { useOrganizationInvite } from './OrganizationInviteProvider';

type UserInviteModalProps = {
    closeModal: () => void;
    show: boolean;
} & Pick<ComponentProps<typeof InviteModal>, 'container'>;

const UserInviteModal = ({ show, container, closeModal }: UserInviteModalProps) => {
    const { sendUserInvite } = useOrganizationInvite();

    return (
        <InviteModal
            title={translations.FORG_invite_user_title}
            container={container}
            show={show}
            modes={[
                {
                    name: 'email',
                    label: 'email',
                    helpText: translations.FORG_user_invite_form_email_help_text,
                },
                { name: 'id', label: 'ID', helpText: translations.FORG_invite_form_id_help_text },
            ]}
            onClose={closeModal}
            onSend={(input, mode) => {
                if (mode.name === 'email') {
                    sendUserInvite(input);
                } else {
                    sendUserInvite(undefined, input);
                }
            }}
            onValidate={(input, mode) => {
                const message =
                    mode.name === 'email'
                        ? getEmailValidityMessage(input)
                        : getIdValidityMessage(input);

                return message;
            }}
            renderBody={({
                input,
                inputMode,
                validationMessage,
                hasSubmitted,
                handleInputChange,
            }) => {
                return (
                    <>
                        <span className="help-block">
                            {translations.FORG_user_invite_form_help_text}
                        </span>
                        <FormGroup
                            label={
                                inputMode.name === 'email'
                                    ? translations.FORG_user_invite_form_user_email_input_label
                                    : translations.FORG_user_invite_form_user_id_input_label
                            }
                            htmlFor="user-invite-input"
                            isRequired
                            isValid={validationMessage.length === 0}
                            validationMessage={validationMessage}
                            showValidation={hasSubmitted}
                        >
                            <input
                                id="user-invite-input"
                                className="form-control"
                                maxLength={inputMode.name === 'email' ? 255 : GUID_REQUIRED_LENGTH}
                                onChange={({ target: { value } }) => handleInputChange(value)}
                                value={input}
                            />
                        </FormGroup>
                    </>
                );
            }}
        />
    );
};

export default UserInviteModal;
