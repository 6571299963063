import translations from '../../translations';
import { formatTenantName, stringReplace } from '../../utils';
import { useAuth } from '../AuthProvider';
import Loader from '../loader/Loader';
import ReceivedInvites from './ReceivedInvites';
import { useOrganizationInvite } from './OrganizationInviteProvider';
import type { OrganizationInvite } from '../../sources/organization';

const ReceivedTenantInviteList = () => {
    const { tenant } = useAuth();

    const {
        receivedTenantInvites,
        loadReceivedTenantInvites,
        acceptTenantInvite,
        rejectTenantInvite,
    } = useOrganizationInvite();

    const matchTenantInvite = (invite: OrganizationInvite) => {
        if (!tenant?.id) {
            return false;
        }

        if (invite.type !== 'tenant') {
            return false;
        }

        if (invite.tenant.id !== tenant.id) {
            return false;
        }

        return true;
    };

    const handleAccept = async (id: string) => {
        await acceptTenantInvite(id);
    };

    const handleReject = async (id: string) => {
        await rejectTenantInvite(id);
    };

    if (!tenant) {
        return <Loader />;
    }

    return (
        <>
            <p className="description">{translations.FORG_tenant_invitations_description}</p>
            <ReceivedInvites
                caption={stringReplace(translations.FORG_received_tenant_invites_table_caption, {
                    tenantName: formatTenantName(tenant.developerName),
                })}
                invitations={receivedTenantInvites}
                matchInvite={matchTenantInvite}
                loadInvites={loadReceivedTenantInvites}
                onAccept={handleAccept}
                onReject={handleReject}
            />
        </>
    );
};

export default ReceivedTenantInviteList;
