import Toggle from '../../inputs/Toggle';
import { ExInput, ExTextarea } from '@boomi/exosphere';
import FormGroup from '../../generic/FormGroup';
import HTMLInput from '../../generic/HTMLEditor/HTMLInput';
import type { ContentType } from '../../../types';
import CodeTypeEditor from './CodeTypeEditor';

interface Props {
    value: string | boolean | number;
    type: ContentType;
    format?: string;
    label?: string;
    placeholder?: string;
    isRequired: boolean;
    disabled?: boolean;
    hasHTMLInputPlugins?: boolean;
    onChange: (value: string | boolean | number) => void;
    onContentFormatChange?: (codeType: string) => void;
}

const PrimitiveContentTypeEditor = ({
    value,
    type,
    onChange,
    label,
    isRequired,
    placeholder,
    disabled = false,
    hasHTMLInputPlugins = true,
    onContentFormatChange,
    format,
}: Props) => {
    if (type === 'ContentCode' && !format) {
        format = 'yaml';
    }
    switch (type) {
        case 'ContentBoolean': {
            const booleanContentValue =
                typeof value === 'boolean'
                    ? value
                    : typeof value === 'string'
                      ? value.toLowerCase() === 'true'
                      : false;

            return (
                <div className="form-group">
                    <label htmlFor="default-content-value-boolean">
                        <Toggle
                            id="default-content-value-boolean"
                            isOn={booleanContentValue}
                            onChange={({ isOn }) => onChange(isOn)}
                            testId="default-value"
                        />
                        {label || ''}
                    </label>
                </div>
            );
        }
        case 'ContentString':
        case 'ContentEncrypted':
            return (
                <ExTextarea
                    dangerouslyUseHTML
                    label={label || ''}
                    required={isRequired}
                    value={value as string}
                    onInput={(e: Event) => onChange((e.target as HTMLTextAreaElement).value)}
                    rows={5}
                    placeholder={placeholder || ''}
                    disabled={disabled}
                    data-testId="default-content-value-string"
                />
            );
        case 'ContentContent':
            return (
                <FormGroup
                    label={label || ''}
                    isRequired={isRequired}
                    htmlFor="default-content-value-content"
                >
                    <HTMLInput
                        height={240}
                        contentValue={value as string}
                        onChange={onChange}
                        hasLinkPlugin={hasHTMLInputPlugins}
                        hasImagePlugin={hasHTMLInputPlugins}
                        hasValuePickerPlugin={hasHTMLInputPlugins}
                    />
                </FormGroup>
            );

        case 'ContentNumber':
            return (
                <ExInput
                    label={label || ''}
                    required={isRequired}
                    value={value as string}
                    onInput={(e: Event) => onChange((e.target as HTMLInputElement).value)}
                    placeholder={placeholder || ''}
                    disabled={disabled}
                    type="number"
                    data-testId="default-content-value-number"
                    step="any"
                />
            );
        case 'ContentDateTime':
            return (
                <ExInput
                    label={label || ''}
                    required={isRequired}
                    value={value as string}
                    onInput={(e: Event) => onChange((e.target as HTMLInputElement).value)}
                    placeholder={placeholder || ''}
                    disabled={disabled}
                    type="datetime-local"
                    data-testId="default-content-value-datetime"
                />
            );
        case 'ContentDate':
            return (
                <ExInput
                    label={label || ''}
                    required={isRequired}
                    value={value as string}
                    onInput={(e: Event) => onChange((e.target as HTMLInputElement).value)}
                    placeholder={placeholder || ''}
                    disabled={disabled}
                    type="date"
                    data-testId="default-content-value-date"
                />
            );
        case 'ContentPassword':
            return (
                <ExInput
                    label={label || ''}
                    required={isRequired}
                    value={value as string}
                    onInput={(e: Event) => onChange((e.target as HTMLInputElement).value)}
                    placeholder={placeholder || ''}
                    disabled={disabled}
                    type="password"
                    togglePassword={true}
                    data-testId="default-content-value-password"
                />
            );
        case 'ContentCode':
            return (
                <CodeTypeEditor
                    editorLabel={label || ''}
                    codeType={format ?? 'yaml'}
                    value={value}
                    isRequired={isRequired}
                    onCodeTypeChange={onContentFormatChange}
                    onCodeEditorChange={onChange}
                />
            );
    }

    return null;
};

export default PrimitiveContentTypeEditor;
