import { useRef } from 'react';
import { Editor as ReactTinyMCEEditor } from '@tinymce/tinymce-react';
import tinymce, { type Editor as TinyMCEEditor } from 'tinymce';

import 'tinymce/tinymce';
import 'tinymce/models/dom/model';
import 'tinymce/themes/silver';
import 'tinymce/icons/default';
import 'tinymce/skins/ui/oxide/skin';
import 'tinymce/skins/content/default/content';
import 'tinymce/skins/ui/oxide/content';

// Plugins
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/media';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/table';
import 'tinymce/plugins/code';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/advlist';
import { file_picker_callback } from './plugins/tinymce-asset-manager-reference';
import tinyMCEValuePlugin from './plugins/tinymce-value-reference';

tinymce.PluginManager.add('flow_elements', tinyMCEValuePlugin);

interface Props {
    contentValue: string;
    onChange: (value: string) => void;
    height: number;
    hasLinkPlugin?: boolean;
    hasImagePlugin?: boolean;
    hasValuePickerPlugin?: boolean;
}

const HTMLInput = ({
    contentValue,
    onChange,
    height,
    hasLinkPlugin = false,
    hasImagePlugin = false,
    hasValuePickerPlugin = false,
}: Props) => {
    const editorRef = useRef<TinyMCEEditor | null>(null);

    const plugins = [
        'advlist',
        'lists',
        'anchor',
        'wordcount',
        'code',
        'media',
        'table',
        'directionality',
    ];

    if (hasLinkPlugin) {
        plugins.push('link');
        plugins.push('autolink');
    }
    if (hasImagePlugin) {
        plugins.push('image');
    }
    if (hasValuePickerPlugin) {
        plugins.push('flow_elements');
    }

    return (
        <ReactTinyMCEEditor
            licenseKey="gpl"
            value={contentValue}
            onEditorChange={onChange}
            onInit={(_evt, editor) => {
                editorRef.current = editor;
            }}
            init={{
                height,
                toolbar: `undo redo styles bold italic alignleft aligncenter alignright alignjustify bullist numlist outdent indent 
                ${hasLinkPlugin ? 'link ' : ''} ${hasImagePlugin ? 'image ' : ''} ${
                    hasValuePickerPlugin ? 'flow_elements ' : ''
                }`,
                link_default_target: '_blank',
                toolbar_mode: 'sliding',
                content_style:
                    'body { font-family: Verdana, Arial, Helvetica, sans-serif; font-size: 14px }',
                file_picker_callback: file_picker_callback,
                plugins,
                promotion: false,
            }}
        />
    );
};

export default HTMLInput;
