import { useCallback, useEffect, useReducer } from 'react';
import { deleteTheme, getThemeList } from '../../sources/theme';
import type {
    AddNotification,
    Filter,
    ItemCollectionResponse,
    OrderDirection,
    ThemeAPI,
    ThemeEdit,
} from '../../types';
import Table, { type TableColumnList } from '../generic/Table';
import {
    ExButton,
    ButtonFlavor,
    ButtonType,
    ButtonSize,
    ExDialog,
    ExIcon,
    IconVariant,
} from '@boomi/exosphere';
import { Trash } from '@phosphor-icons/react';
import translations from '../../translations';
import Sortable from '../generic/Sortable';

type ViewState = 'list' | 'loading';

interface State {
    items: ThemeAPI[];
    viewState: ViewState;
    selectedThemeId: null | string;
    showConfirmDeleteModal: boolean;
    isDeleting: boolean;
    sortBy: {
        property: keyof ThemeAPI;
        direction: OrderDirection;
    };
}

const initialState: State = {
    items: [],
    viewState: 'list',
    selectedThemeId: null,
    showConfirmDeleteModal: false,
    isDeleting: false,
    sortBy: {
        property: 'dateModified',
        direction: 'DESC',
    },
};

const createTheme = (): ThemeEdit => ({
    id: null,
    developerName: '',
    developerSummary: null,
    cssURLs: [],
    jsURLs: [],
    elementType: 'THEME',
    documentTitle: null,
    logoURL: null,
    title: null,
    properties: {
        '--color-primary': '#033d58',
        '--color-primary-font': '#ffffff',
        '--color-font': '#454545',
        '--color-background': '#ffffff',
        '--color-border': '#454545',
        '--color-highlight-background': '#4d7689',
        '--color-highlight-font': '#ffffff',
        '--color-selected-background': '#127b87',
        '--color-selected-font': '#ffffff',
        '--color-background-alternate': '#eeeeee',
        '--font-family':
            "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
    },
});

type Action =
    | { type: 'itemsLoadRequest' }
    | {
          type: 'itemsLoadSuccess';
          payload: { response: ItemCollectionResponse<ThemeAPI> };
      }
    | { type: 'itemsLoadFail' }
    | {
          type: 'clickThemeDelete';
          payload: { themeId: string };
      }
    | {
          type: 'themeDeleteCancel';
      }
    | {
          type: 'themeDeleteRequest';
      }
    | {
          type: 'themeDeleteSuccess';
      }
    | {
          type: 'themeDeleteFail';
      }
    | {
          type: 'sort';
          payload: { property: keyof ThemeAPI; direction: OrderDirection };
      };

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'itemsLoadRequest':
            return {
                ...state,
                viewState: 'loading',
            };

        case 'itemsLoadSuccess':
            return {
                ...state,
                items: action.payload.response.items,
                viewState: 'list',
            };

        case 'itemsLoadFail':
            return {
                ...state,
                viewState: 'list',
            };

        case 'clickThemeDelete':
            return {
                ...state,
                selectedThemeId: action.payload.themeId,
                showConfirmDeleteModal: true,
            };

        case 'themeDeleteCancel':
            return {
                ...state,
                showConfirmDeleteModal: false,
                selectedThemeId: null,
            };

        case 'themeDeleteRequest':
            return {
                ...state,
                isDeleting: true,
            };

        case 'themeDeleteSuccess':
            return {
                ...state,
                isDeleting: false,
                showConfirmDeleteModal: false,
                selectedThemeId: null,
            };

        case 'themeDeleteFail':
            return {
                ...state,
                isDeleting: false,
                showConfirmDeleteModal: false,
                selectedThemeId: null,
            };

        case 'sort':
            return {
                ...state,
                sortBy: {
                    property: action.payload.property,
                    direction: action.payload.direction,
                },
            };
    }
};

interface Props {
    addNotification: AddNotification;
    setItemToEdit: (item: ThemeEdit) => void;
}

const ThemeList = ({ setItemToEdit, addNotification }: Props) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const fetchItems = useCallback(
        async (filter?: Filter) => {
            dispatch({ type: 'itemsLoadRequest' });
            try {
                const response = await getThemeList(filter);
                dispatch({ type: 'itemsLoadSuccess', payload: { response } });
            } catch (error) {
                dispatch({ type: 'itemsLoadFail' });
                addNotification({
                    type: 'error',
                    message: (error as Error).message,
                    isPersistent: true,
                });
            }
        },
        [addNotification],
    );

    const sortItems = useCallback(
        async ({
            property,
            direction,
        }: {
            property: keyof ThemeAPI;
            direction: OrderDirection;
        }) => {
            try {
                await fetchItems({
                    orderDirection: direction,
                    orderBy: property,
                });
                dispatch({ type: 'sort', payload: { property, direction } });
            } catch (error) {
                addNotification({
                    type: 'error',
                    message: (error as Error).message,
                    isPersistent: true,
                });
            }
        },
        [addNotification, fetchItems],
    );

    const deleteThemeConfirm = useCallback(async () => {
        if (state.selectedThemeId === null) {
            return;
        }

        dispatch({ type: 'themeDeleteRequest' });
        try {
            await deleteTheme({ themeId: state.selectedThemeId });
            dispatch({ type: 'themeDeleteSuccess' });
            await fetchItems();
        } catch (error) {
            dispatch({ type: 'themeDeleteFail' });
            addNotification({
                type: 'error',
                message: (error as Error).message,
                isPersistent: true,
            });
        }
    }, [addNotification, fetchItems, state.selectedThemeId]);

    const columns: TableColumnList<ThemeAPI> = [
        {
            renderHeader: () => (
                <Sortable
                    onSort={(direction) => sortItems({ property: 'developerName', direction })}
                    direction={
                        state.sortBy.property === 'developerName' ? state.sortBy.direction : null
                    }
                    defaultDirection={'ASC'}
                >
                    Name
                </Sortable>
            ),
            renderCell: ({ item: theme }) => (
                <button className="link-emulate" onClick={() => setItemToEdit(theme)} type="button">
                    {theme.developerName}
                </button>
            ),
        },
        {
            renderHeader: () => 'Description',
            renderCell: ({ item: theme }) => theme.developerSummary,
        },
        {
            renderHeader: () => (
                <Sortable
                    onSort={(direction) => sortItems({ property: 'dateModified', direction })}
                    direction={
                        state.sortBy.property === 'dateModified' ? state.sortBy.direction : null
                    }
                    defaultDirection={'DESC'}
                >
                    Date Modified
                </Sortable>
            ),
            renderCell: ({ item: theme }) => new Date(theme.dateModified).toLocaleString(),
        },
        {
            renderHeader: () => 'Actions',
            renderCell: ({ item: theme }) => (
                <div className="action-btn-wrapper">
                    <button
                        className="table-icon table-icon-delete"
                        onClick={() =>
                            dispatch({ type: 'clickThemeDelete', payload: { themeId: theme.id } })
                        }
                        aria-label={`${translations.COMMON_delete} ${theme.developerName}`}
                        type="button"
                    >
                        <Trash />
                    </button>
                </div>
            ),
            size: '5rem',
        },
    ];

    useEffect(() => {
        fetchItems();
    }, [fetchItems]);

    return (
        <div className="admin-page theme-list">
            <h1>Themes</h1>
            <span className="flex">
                <ExButton
                    className="flex-child-right"
                    onClick={() => setItemToEdit(createTheme())}
                    flavor={ButtonFlavor.BRANDED}
                    type={ButtonType.PRIMARY}
                    size={ButtonSize.DEFAULT}
                >
                    New Theme
                </ExButton>
            </span>
            <Table<ThemeAPI> wrapperClassName="margin-top" items={state.items} columns={columns} />
            {state.showConfirmDeleteModal && (
                <ExDialog open dialogTitle="Delete this theme?">
                    This will permanently delete the theme.
                    <ExIcon slot="icon" icon="trash" variant={IconVariant.DANGER} />
                    <ExButton
                        slot="footer"
                        flavor={ButtonFlavor.RISKY}
                        type={ButtonType.SECONDARY}
                        size={ButtonSize.LARGE}
                        onClick={deleteThemeConfirm}
                    >
                        Delete
                    </ExButton>
                    <ExButton
                        slot="footer"
                        flavor={ButtonFlavor.BASE}
                        type={ButtonType.SECONDARY}
                        size={ButtonSize.LARGE}
                        onClick={() => dispatch({ type: 'themeDeleteCancel' })}
                    >
                        Cancel
                    </ExButton>
                </ExDialog>
            )}
        </div>
    );
};

export default ThemeList;
