import {
    getTenants,
    getUsers,
    sendTenantInvite,
    rejectTenantInvite,
    acknowledgeRejectedTenantInvite,
    cancelTenantInvite,
    getSentTenantInvites,
    sendUserInvite,
    cancelUserInvite,
    acknowledgeRejectedUserInvite,
    getSentUserInvites,
    getReceivedTenantInvites,
    getReceivedUserInvites,
} from '../../../ts/sources/organization';
import { addNotification } from './notification';
import actionTypes from './actionTypes';
import { NOTIFICATION_TYPES } from '../../../ts/constants';

export const fetchTenants = () => async (dispatch) => {
    try {
        const tenants = await getTenants();

        dispatch({
            type: actionTypes.ORGANIZATION_TENANTS_SET_ALL,
            payload: tenants,
        });
    } catch (err) {
        dispatch(
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: err.message,
                isPersistent: true,
            }),
        );
    }
};

export const fetchUsers = () => async (dispatch) => {
    try {
        const users = await getUsers();

        dispatch({
            type: actionTypes.ORGANIZATION_USERS_SET_ALL,
            payload: users,
        });
    } catch (err) {
        dispatch(
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: err.message,
                isPersistent: true,
            }),
        );
    }
};
