import { useState } from 'react';
import type { NotifyError } from '../../types';
import ScreenRouter from '../generic/ScreenRouter';
import { AnomalyDashboardProvider } from '../anomalyDetection/AnomalyProvider';
import AnomalyDashboard from '../anomalyDetection/AnomalyDashboard';
import ErrorsInsights from './insightsDetailed/ErrorsInsights';
import { InsightsProvider } from './InsightsProvider';
import UsageInsights from './insightsDetailed/UsageInsights';
import StatesInsights from './insightsDetailed/StatesInsights';
import type { StateResponseAPI } from '../../types/states';
import ConnectorInvokerRequestInsights from './insightsDetailed/ConnectorInvokerRequestInsights';
import InsightsSummary from './insightsSummary/InsightsSummary';

import './developer-dashboard.less';

export enum View {
    summary = 'summary',
    usage = 'usage',
    anomaly = 'anomaly',
    error = 'error',
    states = 'states',
    connectorInvokerRequests = 'connectorInvokerRequests',
}

export type SelectedFlow = {
    id: string;
    name: string;
};

interface Props {
    notifyError: NotifyError;
}

const InsightsDashboard = ({ notifyError }: Props) => {
    const [currentView, setCurrentView] = useState<View>(View.summary);
    const [selectedFlow, setSelectedFlow] = useState<SelectedFlow>({ id: '', name: '' });
    const [stateDetails, setStateDetails] = useState<StateResponseAPI | null>(null);

    return (
        <div className="admin-page">
            <AnomalyDashboardProvider notifyError={notifyError} initialDateIndex={0}>
                <InsightsProvider notifyError={notifyError}>
                    <ScreenRouter currentScreen={currentView}>
                        <InsightsSummary
                            screen={View.summary}
                            setCurrentView={setCurrentView}
                            notifyError={notifyError}
                        />
                        <UsageInsights
                            screen={View.usage}
                            notifyError={notifyError}
                            setCurrentView={(view: View, selectedFlow: SelectedFlow) => {
                                setCurrentView(view);
                                setSelectedFlow(selectedFlow);
                            }}
                        />
                        <AnomalyDashboard screen={View.anomaly} setCurrentView={setCurrentView} />
                        <ErrorsInsights
                            screen={View.error}
                            setCurrentView={setCurrentView}
                            notifyError={notifyError}
                        />
                        <StatesInsights
                            screen={View.states}
                            setCurrentView={setCurrentView}
                            selectedFlow={selectedFlow}
                            setStateDetails={setStateDetails}
                            notifyError={notifyError}
                        />
                        <ConnectorInvokerRequestInsights
                            screen={View.connectorInvokerRequests}
                            selectedFlow={selectedFlow}
                            stateDetails={stateDetails}
                            setStateDetails={setStateDetails}
                            setCurrentView={setCurrentView}
                            setSelectedFlow={setSelectedFlow}
                            notifyError={notifyError}
                        />
                    </ScreenRouter>
                </InsightsProvider>
            </AnomalyDashboardProvider>
        </div>
    );
};

export default InsightsDashboard;
