import { pathOr } from 'ramda';
import { COMPONENT_CONFIGURATION_PATH as cfgPath } from '../../../../constants';
import {
    requiredConfigurationOptions,
    hasConfigurationOptions,
    formatAttributes,
} from '../../../../utils';
import { usePageEditor } from '../../../PageEditorProvider';
import { useComposer } from '../ComposerProvider';
import type {
    ComponentConfigurationOptions,
    ComposerElement,
    ListFilter,
    ObjectDataRequest,
    ValueElementIdAPI,
    column,
} from '../../../../../../types';

import DataPresentationConfig from './component-configuration/data-presentation-config/DataPresentationConfig';
import DataSourceConfig from './component-configuration/data-source-config/DataSourceConfig';
import FileDataSourceConfig from './component-configuration/file-data-source-config/FileDataSourceConfig';
import FilterConfig from './component-configuration/filter-config/FilterConfig';
import GeneralSection from './component-configuration/general-config/GeneralSection';
import ModifiersSection from './component-configuration/modifiers-config/ModifiersSection';
import ValidationConfig from './component-configuration/validation-config/ValidationConfig';
import StylesConfig from './component-configuration/styles-config/StylesConfig';
import StateValueConfig from './component-configuration/state-value-config/StateValueConfig';
import PageElementAttributes from './component-configuration/attributes-config/PageElementAttributes';
import ContainerRouter from './container-configuration/ContainerRouter';
import ElementConfigHeader from './ElementConfigHeader';
import HelpSection from './component-configuration/help-config/HelpSection';
import DataSource2Config from './component-configuration/data-source-2-config/DataSource2Config';

const ElementConfig = () => {
    const { getComponent } = usePageEditor();
    const {
        dragDropElements,
        onPageElementUpdate,
        elementToEdit: elementToEditId,
        onSetPageElementActive,
        addNotification,
    } = useComposer();

    const elementToConfigure = dragDropElements
        ? dragDropElements.find((element) => element.id === elementToEditId)
        : (null as ComposerElement | null);
    const elementToConfigureId = elementToConfigure?.id;

    const componentType = elementToConfigure?.componentType;
    const componentConfigOptions = componentType
        ? hasConfigurationOptions(getComponent(componentType).configuration)
        : ({} as { [key: string]: boolean });

    const componentConfigRequired = componentType
        ? requiredConfigurationOptions(getComponent(componentType).required)
        : ({} as ComponentConfigurationOptions);
    const attributes = componentType ? getComponent(componentType)?.attributes : [];

    const setValueToSaveState = (value: ValueElementIdAPI | null) => {
        const valueToSave = value
            ? {
                  command: null,
                  id: value.id,
                  typeElementPropertyId: value.typeElementPropertyId,
              }
            : null;

        if (elementToConfigureId) {
            onPageElementUpdate(elementToConfigureId, cfgPath['STATE_VALUE'], valueToSave);
        }
    };

    const isConnectorDataSource = elementToConfigure?.objectDataRequest !== null;

    const {
        hasDataSource,
        hasDataSourceFilter,
        hasDataPresentation,
        hasStateValue,
        hasFileDataSource,
        hasDataSource2,
        hasEditableColumns,
        canUseCustomComponent,
    } = componentConfigOptions ?? {};

    const updateName = (name: string) =>
        onPageElementUpdate(elementToConfigureId || '', cfgPath['NAME'], name);
    const updateLabel = (label: string) =>
        onPageElementUpdate(elementToConfigureId || '', cfgPath['LABEL'], label);
    const updateContent = (content: string) =>
        onPageElementUpdate(elementToConfigureId || '', cfgPath['CONTENT'], content);
    const updateImageUri = (imageUri: string) =>
        onPageElementUpdate(elementToConfigureId || '', cfgPath['IMAGE_URI'], imageUri);
    const updateWidth = (width: number) =>
        onPageElementUpdate(elementToConfigureId || '', cfgPath['WIDTH'], width);
    const updateHeight = (height: number) =>
        onPageElementUpdate(elementToConfigureId || '', cfgPath['HEIGHT'], height);
    const updateSource = (value: string | ValueElementIdAPI | null) =>
        onPageElementUpdate(elementToConfigureId || '', cfgPath['ATTRIBUTES'], {
            ...(elementToConfigure?.attributes ?? {}),
            $src: value ?? '',
        });
    const updateAutoUpload = (value: boolean) =>
        onPageElementUpdate(elementToConfigureId || '', cfgPath['ATTRIBUTES'], {
            ...(elementToConfigure?.attributes ?? {}),
            $autoUpload: (value ?? '').toString(),
        });

    const updateSortableColumns = (value: boolean) =>
        onPageElementUpdate(elementToConfigureId || '', cfgPath['ATTRIBUTES'], {
            ...(elementToConfigure?.attributes ?? {}),
            $hasSortableColumns: (value ?? '').toString(),
        });

    const updateCsvExportButton = (value: boolean) =>
        onPageElementUpdate(elementToConfigureId || '', cfgPath['ATTRIBUTES'], {
            ...(elementToConfigure?.attributes ?? {}),
            $exportCsvButton: (value ?? '').toString(),
        });

    const updateCsvFileName = (value: string) =>
        onPageElementUpdate(elementToConfigureId || '', cfgPath['ATTRIBUTES'], {
            ...(elementToConfigure?.attributes ?? {}),
            $csvFileName: value ?? '',
        });

    const updateHelpInfo = (helpInfo: string) =>
        onPageElementUpdate(elementToConfigureId || '', cfgPath['HELP_INFO'], helpInfo);
    const updateHintValue = (hintValue: string) =>
        onPageElementUpdate(elementToConfigureId || '', cfgPath['HINT_VALUE'], hintValue);

    const updateEditable = (isEditable: boolean) =>
        onPageElementUpdate(elementToConfigureId || '', cfgPath['EDITABLE'], isEditable);
    const updateMultiSelect = (isMultiSelect: boolean) =>
        onPageElementUpdate(elementToConfigureId || '', cfgPath['MULTISELECT'], isMultiSelect);
    const updateSearchableDisplayColumns = (isSearchableDisplayUpdate: boolean) =>
        onPageElementUpdate(
            elementToConfigureId || '',
            cfgPath['SEARCHABLEDISPLAYCOLUMNS'],
            isSearchableDisplayUpdate,
        );
    const updateSearchable = (isSearchable: boolean) =>
        onPageElementUpdate(elementToConfigureId || '', cfgPath['SEARCHABLE'], isSearchable);

    const dataSourceList = pathOr(null, cfgPath['DATA_SOURCE_LIST'], elementToConfigure);
    const dataSourceConnector = pathOr(null, cfgPath['DATA_SOURCE_CONNECTOR'], elementToConfigure);

    const updateDataSourceList = (value: ValueElementIdAPI | null) =>
        onPageElementUpdate(elementToConfigureId || '', cfgPath['DATA_SOURCE_LIST'], value);
    const updateDataSourceConnector = (value: ObjectDataRequest | null) =>
        onPageElementUpdate(elementToConfigureId || '', cfgPath['DATA_SOURCE_CONNECTOR'], value);
    const updateDataPresentation = (value: column[] | null | undefined) =>
        onPageElementUpdate(elementToConfigureId || '', cfgPath['DATA_PRESENTATION'], value);

    const dataSourceFilter = pathOr(null, cfgPath['DATA_SOURCE_FILTER'], elementToConfigure);

    const updateDataSourceFilter = (value: ListFilter | null) =>
        onPageElementUpdate(elementToConfigureId || '', cfgPath['DATA_SOURCE_FILTER'], value);

    const dataPresentation = pathOr(null, cfgPath['DATA_PRESENTATION'], elementToConfigure);
    const formattedAttributes = formatAttributes(
        elementToConfigure?.attributes as Record<string, string>,
    );

    const updateFileRequestResourcePath = (resourcePath: string) =>
        onPageElementUpdate(
            elementToConfigureId || '',
            cfgPath['FILE_DATA_REQUEST_RESOURCE_PATH'],
            resourcePath,
        );

    const updateFileRequestConnector = (connectorId: string | null) =>
        onPageElementUpdate(
            elementToConfigureId || '',
            cfgPath['FILE_DATA_REQUEST_CONNECTOR_ID'],
            connectorId,
        );

    const updateFileRequestPath = (path: string | null) =>
        onPageElementUpdate(elementToConfigureId || '', cfgPath['FILE_DATA_REQUEST_PATH'], path);

    const updateRequired = (isRequired: boolean) =>
        onPageElementUpdate(elementToConfigureId || '', cfgPath['REQUIRED'], isRequired);
    const updateValidations = (validations: unknown) => {
        onPageElementUpdate(elementToConfigureId || '', cfgPath['VALIDATIONS'], validations);
    };

    const updateClassName = (className: string) =>
        onPageElementUpdate(elementToConfigureId || '', cfgPath['CLASSNAME'], className);

    const dataPresentationPropertiesTypeElement = componentType
        ? getComponent(componentType).dataPresentationPropertiesTypeElement
        : null;

    const stateValueTypeElementFilter = componentType
        ? getComponent(componentType).stateValueTypeElementFilter
        : null;

    const stateValueContentTypeFilter = componentType
        ? getComponent(componentType).stateValueContentTypeFilter
        : null;

    return (
        <>
            <ElementConfigHeader
                elementToConfigure={elementToConfigure}
                onBack={onSetPageElementActive}
            >
                {`Configure ${
                    elementToConfigure?.componentType
                        ? `${elementToConfigure.componentType} component`
                        : elementToConfigure?.containerType?.toUpperCase() === 'CHARTS'
                          ? 'chart component'
                          : 'container'
                }`}
            </ElementConfigHeader>
            {componentType ? (
                <>
                    <GeneralSection
                        generalOptions={componentConfigOptions}
                        generalValues={elementToConfigure}
                        requiredConfiguration={componentConfigRequired}
                        updateName={updateName}
                        updateLabel={updateLabel}
                        updateContent={updateContent}
                        updateImageUri={updateImageUri}
                        updateWidth={updateWidth}
                        updateHeight={updateHeight}
                        updateSource={updateSource}
                    />
                    <ModifiersSection
                        modifierOptions={componentConfigOptions}
                        modifierValues={elementToConfigure}
                        updateEditable={updateEditable}
                        updateMultiSelect={updateMultiSelect}
                        updateSearchable={updateSearchable}
                        updateSearchableDisplayColumns={updateSearchableDisplayColumns}
                        updateAutoUpload={updateAutoUpload}
                        updateSortableColumns={updateSortableColumns}
                        updateCsvExportButton={updateCsvExportButton}
                        updateCsvFileName={updateCsvFileName}
                    />
                    <HelpSection
                        modifierOptions={componentConfigOptions}
                        modifierValues={elementToConfigure}
                        updateHelpInfo={updateHelpInfo}
                        updateHintValue={updateHintValue}
                    />
                    {hasDataSource && (
                        <DataSourceConfig
                            dataSourceList={dataSourceList}
                            dataSourceConnector={dataSourceConnector}
                            updateDataSourceList={updateDataSourceList}
                            updateDataSourceConnector={updateDataSourceConnector}
                            updateDataPresentation={updateDataPresentation}
                            requiredConfiguration={componentConfigRequired}
                        />
                    )}
                    {hasDataSource2 && (
                        <DataSource2Config
                            value={dataSourceConnector}
                            onChange={updateDataSourceConnector}
                        />
                    )}
                    {hasDataSourceFilter && isConnectorDataSource && (
                        <FilterConfig
                            dataSourceConnector={dataSourceConnector}
                            dataSourceFilter={dataSourceFilter}
                            updateDataSourceFilter={updateDataSourceFilter}
                        />
                    )}
                    {hasFileDataSource && (
                        <FileDataSourceConfig
                            updateResourcePath={updateFileRequestResourcePath}
                            updateConnector={updateFileRequestConnector}
                            updatePath={updateFileRequestPath}
                            resourcePath={elementToConfigure.fileDataRequest?.resourcePath || null}
                            selectedConnectorId={
                                elementToConfigure.fileDataRequest?.serviceElementId || null
                            }
                            selectedPath={elementToConfigure.fileDataRequest?.path || null}
                        />
                    )}
                    {hasStateValue && (
                        <StateValueConfig
                            required={componentConfigRequired?.isStateValueRequired}
                            selectedValue={elementToConfigure?.valueElementValueBindingReferenceId}
                            setSelectedValue={setValueToSaveState}
                            dataSourceList={dataSourceList}
                            dataSourceConnector={dataSourceConnector}
                            typeElementId={stateValueTypeElementFilter || null}
                            isMultiSelect={elementToConfigure?.isMultiSelect}
                            stateValueContentTypeFilter={stateValueContentTypeFilter || null}
                            validations={elementToConfigure.validations}
                        />
                    )}

                    {hasStateValue && (
                        <ValidationConfig
                            isRequired={elementToConfigure.isRequired}
                            updateRequired={updateRequired}
                            validations={elementToConfigure.validations || []}
                            updateValidations={updateValidations}
                            valueElementId={
                                elementToConfigure?.valueElementValueBindingReferenceId ?? null
                            }
                            addNotification={addNotification}
                        />
                    )}

                    {hasDataPresentation && (
                        <DataPresentationConfig
                            configuredDataSource={dataSourceList || dataSourceConnector}
                            dataPresentation={dataPresentation}
                            updateDataPresentation={updateDataPresentation}
                            stateSaveValue={elementToConfigure?.valueElementValueBindingReferenceId}
                            defaultSourceTypeElementId={
                                dataPresentationPropertiesTypeElement || null
                            }
                            hasEditableColumns={hasEditableColumns}
                            canUseCustomComponent={canUseCustomComponent}
                        />
                    )}

                    <StylesConfig
                        className={elementToConfigure?.className}
                        updateClassName={updateClassName}
                    />

                    {attributes && (
                        <PageElementAttributes
                            id={elementToConfigureId || ''}
                            attributes={formattedAttributes}
                            attributesList={attributes}
                        />
                    )}
                </>
            ) : (
                <ContainerRouter container={elementToConfigure} />
            )}
        </>
    );
};

export default ElementConfig;
