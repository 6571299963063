import { useEffect, useRef, useState, type ElementRef } from 'react';
import type {
    ChartDataPoint,
    ChartLine,
    StateError,
    StateErrorsLineDataPoint,
    StateErrorsPieChartData,
    StateErrorsPieDataPoint,
    StateErrorsWithFilter,
    TimeFilters,
} from '../../../types/dashboard';
import {
    formatChartDate,
    getDashboardLineChartOptions,
    insightDateFilterItems,
    onHandlePointClick,
} from '../../../utils/dashboard';
import { Chart, type ActiveElement, type ChartConfiguration, type ChartEvent } from 'chart.js/auto';
import { View } from '../InsightsDashboard';
import translations from '../../../translations';
import InsightsDateFilter from '../InsightsDateFilter';
import Table, { type TableColumnList } from '../../generic/Table';
import FormGroup from '../../generic/FormGroup';
import Select from 'react-select';
import { getSharedStyles } from '../../../utils/select';
import { useInsights } from '../InsightsProvider';
import { ExEmptyState } from '@boomi/exosphere';
import InsightsDateRange from '../InsightsDateRange';
import SearchInput from '../../generic/SearchInput';
import type { NotifyError } from '../../../types';
import { getStateErrorsForPieChart, getStateErrorsForTable } from '../../../sources/dashboard';

const aggregateFlows = (stateErrors: StateError[]) => {
    const flowNames: string[] = [];

    stateErrors.forEach((se) => {
        if (!flowNames.includes(se.flowName)) {
            flowNames.push(se.flowName);
        }
    });

    return flowNames;
};

interface Props {
    setCurrentView: (view: View) => void;
    notifyError: NotifyError;
    screen?: string;
}

const ErrorsInsights = ({ setCurrentView, notifyError }: Props) => {
    const lineCanvasRef = useRef<ElementRef<'canvas'>>(null);
    const pieCanvasRef = useRef<ElementRef<'canvas'>>(null);

    // I've bundled the dateFilter and customToDate in with the stateErrors so we only rerender the chart page once.
    const [stateErrorsWithFilter, setStateErrorsWithFilter] = useState<StateErrorsWithFilter>({
        stateErrors: [],
        dateFilter: 'day',
        customToDate: new Date(),
    });

    const [stateErrorsPieChartData, setStateErrorsPieChartData] = useState<StateErrorsPieChartData>(
        { stateErrorsData: [], hasOneFlow: false, stateErrorsTotal: 0 },
    );

    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);

    const {
        errorsDateFilter,
        setErrorsDateFilter,
        customErrorsToDate,
        setCustomErrorsToDate,
        stateErrorsLineChartData,
        errorsFlowNameFilter,
        setErrorsFlowNameFilter,
        errorsSearchFilter,
        setErrorsSearchFilter,
    } = useInsights();

    useEffect(() => {
        getStateErrorsForTable(
            errorsDateFilter,
            customErrorsToDate,
            page,
            errorsSearchFilter,
            errorsFlowNameFilter,
        )
            .then((response) => {
                setStateErrorsWithFilter({
                    stateErrors: response.items,
                    dateFilter: errorsDateFilter,
                    customToDate: customErrorsToDate,
                });

                setTotal(response._meta.total);
            })
            .catch(notifyError);

        getStateErrorsForPieChart(
            errorsDateFilter,
            customErrorsToDate,
            errorsSearchFilter,
            errorsFlowNameFilter,
        )
            .then((response) => setStateErrorsPieChartData(response))
            .catch(notifyError);
    }, [
        customErrorsToDate,
        errorsDateFilter,
        errorsFlowNameFilter,
        errorsSearchFilter,
        page,
        notifyError,
    ]);

    useEffect(() => {
        let lineChart: Chart<
            'line',
            {
                x: string;
                y: number;
            }[],
            unknown
        > | null = null;

        let pieChart: Chart<'doughnut', number[], unknown> | null = null;

        const generateLineChart = (
            stateErrorsData: StateErrorsLineDataPoint[],
            dateFilter: TimeFilters,
        ) => {
            const chartLine: ChartLine[] = [];
            const errorsLineData: ChartDataPoint[] = [];

            // Most of the work is done on the backend but we leave property mapping and date formatting to the client.
            stateErrorsData.forEach((data) => {
                errorsLineData.push({
                    x: formatChartDate(new Date(data.bucket), dateFilter),
                    y: data.count,
                    rawDate: new Date(data.bucket),
                });
            });

            chartLine.push({
                data: errorsLineData,
                label: 'Errors',
                tension: 0.3,
                backgroundColor: '#C73D58',
                borderColor: '#C73D58',
            });

            const context = lineCanvasRef.current?.getContext('2d');

            if (!context) {
                return null;
            }

            const chartConfig: ChartConfiguration<'line', { x: string; y: number }[]> = {
                type: 'line',
                data: { datasets: chartLine },
                options: getDashboardLineChartOptions(
                    (_event: ChartEvent, elements: ActiveElement[]) =>
                        onHandlePointClick(
                            chartLine[0].data[elements[0].index],
                            stateErrorsLineChartData.dateFilter,
                            setErrorsDateFilter,
                            setCustomErrorsToDate,
                        ),
                ),
            };

            return new Chart(context, chartConfig);
        };

        const generatePieChart = (
            stateErrorsData: StateErrorsPieDataPoint[],
            hasOneFlow: boolean,
        ) => {
            const pieData: number[] = stateErrorsData.map((se) => se.count);
            const pieLabels: string[] = stateErrorsData.map((se) => se.name);

            const context = pieCanvasRef.current?.getContext('2d');

            if (!context) {
                return null;
            }

            const chartConfig: ChartConfiguration<'doughnut', number[]> = {
                type: 'doughnut',
                data: {
                    datasets: [{ data: pieData }],
                    labels: pieLabels,
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: true,
                    plugins: {
                        legend: { position: 'top', labels: { boxWidth: 35 }, maxWidth: 150 },
                    },
                    onClick: (_e, elements) => {
                        if (!hasOneFlow) {
                            const label = pieLabels[elements[0].index];
                            setErrorsFlowNameFilter(label);
                        }
                    },
                },
            };

            return new Chart(context, chartConfig);
        };

        lineChart = generateLineChart(
            stateErrorsLineChartData.stateErrorsData,
            stateErrorsLineChartData.dateFilter,
        );

        pieChart = generatePieChart(
            stateErrorsPieChartData.stateErrorsData,
            stateErrorsPieChartData.hasOneFlow,
        );

        // Cleanup the chart to prevent it from wildly animating when switching away and then back to Dashboard tab.
        return () => {
            lineChart?.destroy();
            pieChart?.destroy();
        };
    }, [
        stateErrorsLineChartData,
        stateErrorsPieChartData,
        setCustomErrorsToDate,
        setErrorsDateFilter,
        setErrorsFlowNameFilter,
    ]);

    const columns: TableColumnList<StateError> = [
        {
            renderHeader: () => translations.COMMON_TABLE_message,
            renderCell: ({ item }) => <span title={item.message}>{item.message}</span>,
        },
        {
            renderHeader: () => translations.COMMON_TABLE_name,
            renderCell: ({ item }) => <span title={item.flowName}>{item.flowName}</span>,
        },
        {
            renderHeader: () => translations.COMMON_TABLE_map_element_name,
            renderCell: ({ item }) => (
                <span title={item.mapElementName}>{item.mapElementName}</span>
            ),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_status_code,
            renderCell: ({ item }) => item.statusCode,
            size: '8rem',
        },
        {
            renderHeader: () => translations.ANOMALY_date_time,
            renderCell: ({ item }) =>
                new Date(item.dateTime).toLocaleString(navigator.language, {
                    dateStyle: 'medium',
                    timeStyle: 'short',
                }),
            size: '12rem',
        },
    ];

    const hasData = total > 0;

    return (
        <>
            <span className="title-bar">
                <h1>
                    <button
                        className="link-emulate"
                        onClick={() => setCurrentView(View.summary)}
                        type="button"
                    >
                        {translations.DASHBOARD_header}
                    </button>
                    {' > '}
                    {translations.DASHBOARD_errors_header}
                </h1>
            </span>
            <div className="insights-control-bar">
                <div className="insights-control-group">
                    <div className="insights-search">
                        <FormGroup
                            label={translations.DASHBOARD_search}
                            htmlFor="errorInsightsSearch"
                        >
                            <SearchInput
                                className="insights-search-input"
                                value={errorsSearchFilter}
                                onChange={(searchValue) => setErrorsSearchFilter(searchValue)}
                                placeholder=""
                            />
                        </FormGroup>
                    </div>
                    <div className="inights-filter-bar-control">
                        <FormGroup
                            label={translations.DASHBOARD_flow_name}
                            htmlFor="errorInsightsFlowFilter"
                        >
                            <Select
                                styles={getSharedStyles<{ label: string; value: string }>()}
                                inputId="errorInsightsFlowFilter"
                                name="errorInsightsFlowFilter"
                                isClearable={!!errorsFlowNameFilter}
                                placeholder={translations.DASHBOARD_errors_filter_flow_playerholder}
                                options={aggregateFlows(stateErrorsWithFilter.stateErrors).map(
                                    (name) => ({
                                        label: name,
                                        value: name,
                                    }),
                                )}
                                onChange={(e) => {
                                    setErrorsFlowNameFilter(e?.value ?? '');
                                }}
                                value={
                                    errorsFlowNameFilter !== ''
                                        ? {
                                              label: errorsFlowNameFilter,
                                              value: errorsFlowNameFilter,
                                          }
                                        : null
                                }
                            />
                        </FormGroup>
                    </div>
                </div>
                <div className="insights-date-range">
                    <InsightsDateRange
                        toDate={customErrorsToDate}
                        dateRange={errorsDateFilter}
                        onChangeToDate={(toDate) => setCustomErrorsToDate(toDate)}
                    />
                    <InsightsDateFilter
                        selectedDate={errorsDateFilter}
                        setSelectedDate={setErrorsDateFilter}
                        dateFilterItems={insightDateFilterItems}
                    />
                </div>
            </div>
            {/* We have to keep the canvas in the DOM otherwise the chart won't render if switching from a state of no data */}
            <span className={hasData ? '' : 'hidden'}>
                <div className="chart-area margin-bottom-large">
                    <div className="line-chart-section">
                        <h2>{translations.DASHBOARD_error_line_header}</h2>
                        <div className="insights-chart">
                            <canvas ref={lineCanvasRef} data-testid="errors-line-chart-canvas" />
                        </div>
                    </div>
                    <div className="pie-chart-section">
                        <h2>
                            {stateErrorsPieChartData.hasOneFlow
                                ? translations.DASHBOARD_error_pie_header_alt
                                : translations.DASHBOARD_error_pie_header}
                        </h2>
                        <div className="insights-pie-chart">
                            <canvas ref={pieCanvasRef} data-testid="errors-doughnut-chart-canvas" />
                        </div>
                    </div>
                </div>
                <Table
                    columns={columns}
                    items={stateErrorsWithFilter.stateErrors}
                    rowClassName={() => 'generic-row generic-row-tall'}
                    pagination={{ page, total, pageSize: 20, changePage: setPage }}
                />
            </span>
            {hasData ? null : (
                <div className="insights-empty-state">
                    <ExEmptyState
                        label={translations.DASHBOARD_no_errors}
                        text={translations.DASHBOARD_no_usage_unfilterd}
                    />
                </div>
            )}
        </>
    );
};

export default ErrorsInsights;
