import {
    Article,
    Browser,
    ChartBar,
    ChartLine,
    ChartPie,
    CheckSquare,
    CircleWavyQuestion,
    CreditCard,
    Disc,
    EyeSlash,
    FileArrowUp,
    FileHtml,
    FilePdf,
    Files as FilesIcon,
    FlowArrow,
    GridFour,
    Hexagon,
    Image as ImageIcon,
    List as ListIcon,
    ListBullets,
    ListNumbers,
    PencilSimple,
    Presentation as PresentationIcon,
    QrCode,
    Scan,
    Signature,
    ToggleLeft,
    CaretDown,
    ChatCenteredDots,
    GridNine,
} from '@phosphor-icons/react';
import { SYSTEM_TYPE_NAME_ID_MAPPINGS } from '../../constants';
import translations from '../../translations';
import type { ComponentRegistry, PageComponent } from '../../types';
import { columnTypes } from './components/gui/componentSuggest/constants';
import {
    BarChartComponent,
    DoughnutChartComponent,
    LineChartComponent,
    PieChartComponent,
    PolarChartComponent,
} from './components/preview/ChartComponent';
import Checkbox from './components/preview/Checkbox';
import CheckboxList from './components/preview/CheckboxList';
import FileUpload from './components/preview/FileUpload';
import Files from './components/preview/Files';
import HiddenInput from './components/preview/HiddenInput';
import Iframe from './components/preview/Iframe';
import Image from './components/preview/Image';
import Input from './components/preview/Input';
import List from './components/preview/List';
import Outcomes from './components/preview/Outcomes';
import Pdf from './components/preview/Pdf';
import Presentation from './components/preview/Presentation';
import Radio from './components/preview/Radio';
import RichText from './components/preview/RichText';
import Select from './components/preview/Select';
import Table from './components/preview/Table';
import Text from './components/preview/Text';
import Toggle from './components/preview/Toggle';
import PaymentCard from './components/preview/paymentCard/PaymentCard';
import CodeScanner from './components/preview/codeScanner/CodeScanner';
import SignaturePad from './components/preview/signaturePad/SignaturePad';
import Tiles from './components/preview/tiles/Tiles';
import {
    COMPONENT_CONFIGURATION_OPTIONS as cfgOption,
    COMPONENT_GROUPS,
    COMPONENT_TYPE,
    RUNTIME_VERSION,
} from './constants';
import CodeGenerator from './components/preview/codeGenerator/CodeGenerator';
import AIChat from './components/preview/AIChat';

export const componentRegistry: { [key: string]: ComponentRegistry } = {
    [COMPONENT_TYPE['INPUT']]: {
        base: null,
        group: COMPONENT_GROUPS['INPUT'],
        type: COMPONENT_TYPE['INPUT'],
        componentSuggestContentTypeFilter: [
            'ContentString',
            'ContentPassword',
            'ContentEncrypted',
            'ContentNumber',
            'ContentDateTime',
            'ContentDate',
            'ContentBoolean',
        ],
        componentSuggestColumnType: columnTypes.none,
        runtimeSupport: RUNTIME_VERSION['BOTH'], // remove this once we switch to new runtime completely
        renderFn: Input,
        ui: {
            icon: <PencilSimple />,
            caption: 'Input',
            shortcut: '---', // Maybe something like CTRL+SHIFT+I?
            // Maybe a description might not be needed if we have a link to the help page.
            description: 'Input component short description. Something something, text and stuff.',
            // Link to the help page for this type of component.
            help: 'https://help.boomi.com/bundle/flow/page/c-flo-Pages_Components_Input_Input.html',
        },
        configuration: [
            cfgOption['NAME'],
            cfgOption['LABEL'],
            cfgOption['EDITABLE'],
            cfgOption['STATE_VALUE'],
            cfgOption['HINT_VALUE'],
        ],
        required: [cfgOption['NAME'], cfgOption['STATE_VALUE']],
        attributes: [
            { label: translations.PAGE_BUILDER_classes_attribute_label, value: 'classes' },
            { label: translations.PAGE_BUILDER_validation_attribute_label, value: 'validation' },
            { label: translations.PAGE_BUILDER_mask_attribute_label, value: 'mask' },
            {
                label: translations.PAGE_BUILDER_autocomplete_attribute_label,
                value: 'autocomplete',
            },
            { label: translations.PAGE_BUILDER_type_attribute_label, value: 'type' },
            { label: translations.PAGE_BUILDER_step_attribute_label, value: 'step' },
            { label: translations.PAGE_BUILDER_minimum_attribute_label, value: 'minimum' },
            { label: translations.PAGE_BUILDER_maximum_attribute_label, value: 'maximum' },
            { label: translations.PAGE_BUILDER_useCurrent_attribute_label, value: 'useCurrent' },
            {
                label: translations.PAGE_BUILDER_dateTimeFormat_attribute_label,
                value: 'dateTimeFormat',
            },
            {
                label: translations.PAGE_BUILDER_dateTimeLocale_attribute_label,
                value: 'dateTimeLocale',
            },
        ],
    },
    [COMPONENT_TYPE['RADIO']]: {
        base: null,
        group: COMPONENT_GROUPS['INPUT'],
        type: COMPONENT_TYPE['RADIO'],
        componentSuggestContentTypeFilter: ['ContentList'],
        componentSuggestColumnType: columnTypes.single,
        runtimeSupport: RUNTIME_VERSION['BOTH'], // remove this once we switch to new runtime completely
        renderFn: Radio,
        ui: {
            icon: <ListBullets />,
            caption: 'Radio',
            shortcut: '---',
            description: 'Mutally exclusive option selection',
            help: 'https://help.boomi.com/bundle/flow/page/c-flo-Pages_Components_Radio.html',
        },
        configuration: [
            cfgOption['NAME'],
            cfgOption['LABEL'],
            cfgOption['MULTISELECT'],
            cfgOption['EDITABLE'],
            cfgOption['DATA_SOURCE_LIST'],
            cfgOption['DATA_SOURCE_CONNECTOR'],
            cfgOption['DATA_SOURCE_FILTER'],
            cfgOption['STATE_VALUE'],
            cfgOption['DATA_PRESENTATION'],
        ],
        required: [
            cfgOption['NAME'],
            cfgOption['DATA_SOURCE_LIST'],
            cfgOption['DATA_SOURCE_LIST_VALUE'],
            cfgOption['DATA_SOURCE_CONNECTOR'],
            cfgOption['DATA_SOURCE_CONNECTOR_BINDING'],
            cfgOption['DATA_SOURCE_CONNECTOR_TYPE'],
        ],
        attributes: [
            { label: translations.PAGE_BUILDER_classes_attribute_label, value: 'classes' },
            { label: translations.PAGE_BUILDER_multiSelect_attribute_label, value: 'multiSelect' },
        ],
    },
    [COMPONENT_TYPE['CHECKBOX']]: {
        base: null,
        group: COMPONENT_GROUPS['INPUT'],
        type: COMPONENT_TYPE['CHECKBOX'],
        componentSuggestContentTypeFilter: ['ContentBoolean'],
        componentSuggestColumnType: columnTypes.none,
        stateValueContentTypeFilter: 'ContentBoolean',
        runtimeSupport: RUNTIME_VERSION['BOTH'], // remove this once we switch to new runtime completely
        renderFn: Checkbox,
        ui: {
            icon: <CheckSquare />,
            caption: 'Checkbox',
            shortcut: '---',
            description: 'True or false option selection',
            help: 'https://help.boomi.com/bundle/flow/page/c-flo-Pages_Components_Checkbox.html',
        },
        configuration: [
            cfgOption['NAME'],
            cfgOption['LABEL'],
            cfgOption['EDITABLE'],
            cfgOption['STATE_VALUE'],
        ],
        required: [cfgOption['NAME'], cfgOption['STATE_VALUE']],
    },
    [COMPONENT_TYPE['CHECKBOXLIST']]: {
        base: {
            isMultiselect: true,
        } as Partial<PageComponent>,
        group: COMPONENT_GROUPS['INPUT'],
        type: COMPONENT_TYPE['CHECKBOXLIST'],
        componentSuggestContentTypeFilter: ['ContentList'],
        componentSuggestColumnType: columnTypes.single,
        stateValueContentTypeFilter: 'ContentList',
        runtimeSupport: RUNTIME_VERSION['NEXT'], // remove this once we switch to new runtime completely
        renderFn: CheckboxList,
        ui: {
            icon: <CheckSquare />,
            caption: 'Checkbox List',
            shortcut: '---',
            description: 'Multi selectable list of options',
            help: null,
        },
        configuration: [
            cfgOption['NAME'],
            cfgOption['LABEL'],
            cfgOption['EDITABLE'],
            cfgOption['STATE_VALUE'],
            cfgOption['DATA_SOURCE_LIST'],
            cfgOption['DATA_SOURCE_CONNECTOR'],
            cfgOption['DATA_PRESENTATION'],
        ],
        required: [
            cfgOption['NAME'],
            cfgOption['DATA_SOURCE_LIST'],
            cfgOption['DATA_SOURCE_LIST_VALUE'],
            cfgOption['DATA_SOURCE_CONNECTOR'],
            cfgOption['DATA_SOURCE_CONNECTOR_TYPE'],
            cfgOption['DATA_SOURCE_CONNECTOR_BINDING'],
        ],
    },
    [COMPONENT_TYPE['TOGGLE']]: {
        base: null,
        group: COMPONENT_GROUPS['INPUT'],
        type: COMPONENT_TYPE['TOGGLE'],
        componentSuggestContentTypeFilter: ['ContentBoolean'],
        componentSuggestColumnType: columnTypes.none,
        stateValueContentTypeFilter: 'ContentBoolean',
        runtimeSupport: RUNTIME_VERSION['BOTH'], // remove this once we switch to new runtime completely
        renderFn: Toggle,
        ui: {
            icon: <ToggleLeft />,
            caption: 'Toggle',
            shortcut: '---',
            description: 'True or false option selection, larger than a checkbox for touch devices',
            help: 'https://help.boomi.com/bundle/flow/page/c-flo-Pages_Components_Toggle.html',
        },
        configuration: [
            cfgOption['NAME'],
            cfgOption['LABEL'],
            cfgOption['EDITABLE'],
            cfgOption['STATE_VALUE'],
        ],
        required: [cfgOption['NAME'], cfgOption['STATE_VALUE']],
        attributes: [
            { label: translations.PAGE_BUILDER_classes_attribute_label, value: 'classes' },
            { label: translations.PAGE_BUILDER_shape_attribute_label, value: 'shape' },
            { label: translations.PAGE_BUILDER_background_attribute_label, value: 'background' },
        ],
    },
    [COMPONENT_TYPE['SELECT']]: {
        base: null,
        group: COMPONENT_GROUPS['INPUT'],
        type: COMPONENT_TYPE['SELECT'],
        componentSuggestContentTypeFilter: ['ContentList'],
        componentSuggestColumnType: columnTypes.single,
        runtimeSupport: RUNTIME_VERSION['BOTH'], // remove this once we switch to new runtime completely
        renderFn: Select,
        ui: {
            icon: <ListIcon />,
            caption: 'Combobox',
            shortcut: '---', // Maybe something like CTRL+SHIFT+S?
            description: 'Combobox component short description.',
            help: null,
        },
        configuration: [
            cfgOption['NAME'],
            cfgOption['LABEL'],
            cfgOption['MULTISELECT'],
            cfgOption['EDITABLE'],
            cfgOption['STATE_VALUE'],
            cfgOption['DATA_SOURCE_LIST'],
            cfgOption['DATA_SOURCE_CONNECTOR'],
            cfgOption['DATA_PRESENTATION'],
            cfgOption['DATA_SOURCE_FILTER'],
            cfgOption['HINT_VALUE'],
        ],
        required: [
            cfgOption['NAME'],
            cfgOption['DATA_SOURCE_LIST'],
            cfgOption['DATA_SOURCE_LIST_VALUE'],
            cfgOption['DATA_SOURCE_CONNECTOR'],
            cfgOption['DATA_SOURCE_CONNECTOR_TYPE'],
            cfgOption['DATA_SOURCE_CONNECTOR_BINDING'],
        ],
        attributes: [
            { label: translations.PAGE_BUILDER_classes_attribute_label, value: 'classes' },
            { label: translations.PAGE_BUILDER_isTethered_attribute_label, value: 'isTethered' },
        ],
    },
    [COMPONENT_TYPE['NATIVESELECT']]: {
        base: null,
        group: COMPONENT_GROUPS['INPUT'],
        type: COMPONENT_TYPE['NATIVESELECT'],
        componentSuggestContentTypeFilter: ['ContentList'],
        componentSuggestColumnType: columnTypes.single,
        runtimeSupport: RUNTIME_VERSION['NEXT'],
        renderFn: Select,
        ui: {
            icon: <CaretDown />,
            caption: 'Select',
            shortcut: '---',
            description: 'Select component short description.',
            help: null,
        },
        configuration: [
            cfgOption['NAME'],
            cfgOption['LABEL'],
            cfgOption['MULTISELECT'],
            cfgOption['EDITABLE'],
            cfgOption['HINT_VALUE'],
            cfgOption['STATE_VALUE'],
            cfgOption['DATA_SOURCE_LIST'],
            cfgOption['DATA_SOURCE_CONNECTOR'],
            cfgOption['DATA_PRESENTATION'],
            cfgOption['DATA_SOURCE_FILTER'],
        ],
        required: [
            cfgOption['NAME'],
            cfgOption['DATA_SOURCE_LIST'],
            cfgOption['DATA_SOURCE_LIST_VALUE'],
            cfgOption['DATA_SOURCE_CONNECTOR'],
            cfgOption['DATA_SOURCE_CONNECTOR_TYPE'],
            cfgOption['DATA_SOURCE_CONNECTOR_BINDING'],
        ],
    },
    [COMPONENT_TYPE['TEXTAREA']]: {
        base: null,
        group: COMPONENT_GROUPS['INPUT'],
        type: COMPONENT_TYPE['TEXTAREA'],
        componentSuggestContentTypeFilter: ['ContentString'],
        componentSuggestColumnType: columnTypes.none,
        stateValueContentTypeFilter: 'ContentString',
        runtimeSupport: RUNTIME_VERSION['BOTH'], // remove this once we switch to new runtime completely
        renderFn: Text,
        ui: {
            icon: <Article />,
            caption: 'Textarea',
            shortcut: '---', // Maybe something like CTRL+SHIFT+A?
            description: 'Textarea component short description.',
            help: null,
        },
        configuration: [
            cfgOption['NAME'],
            cfgOption['LABEL'],
            cfgOption['EDITABLE'],
            cfgOption['STATE_VALUE'],
            cfgOption['HINT_VALUE'],
        ],
        required: [cfgOption['NAME'], cfgOption['STATE_VALUE']],
        attributes: [
            { label: translations.PAGE_BUILDER_classes_attribute_label, value: 'classes' },
        ],
    },
    [COMPONENT_TYPE['CONTENT']]: {
        base: null,
        group: COMPONENT_GROUPS['INPUT'],
        type: COMPONENT_TYPE['CONTENT'],
        componentSuggestContentTypeFilter: ['ContentContent'],
        componentSuggestColumnType: columnTypes.none,
        stateValueContentTypeFilter: 'ContentContent',
        runtimeSupport: RUNTIME_VERSION['BOTH'], // remove this once we switch to new runtime completely
        renderFn: RichText,
        ui: {
            icon: <Article />,
            caption: 'Rich Text',
            shortcut: '---',
            description: 'Get rich text input from the user',
            help: 'https://help.boomi.com/bundle/flow/page/c-flo-Pages_Components_Richtext.html',
        },
        configuration: [
            cfgOption['NAME'],
            cfgOption['LABEL'],
            cfgOption['EDITABLE'],
            cfgOption['STATE_VALUE'],
            cfgOption['HINT_VALUE'],
        ],
        required: [cfgOption['NAME'], cfgOption['STATE_VALUE']],
        attributes: [
            { label: translations.PAGE_BUILDER_classes_attribute_label, value: 'classes' },
        ],
    },
    [COMPONENT_TYPE['TABLE']]: {
        base: null,
        group: COMPONENT_GROUPS['DATA'],
        type: COMPONENT_TYPE['TABLE'],
        componentSuggestContentTypeFilter: ['ContentList'],
        componentSuggestColumnType: columnTypes.multiple,
        runtimeSupport: RUNTIME_VERSION['BOTH'], // remove this once we switch to new runtime completely
        renderFn: Table,
        ui: {
            icon: <Browser />,
            caption: 'Table',
            shortcut: '---', // Maybe something like CTRL+SHIFT+T?
            description: 'Table component short description.',
            help: null,
        },
        configuration: [
            cfgOption['NAME'],
            cfgOption['LABEL'],
            cfgOption['MULTISELECT'],
            cfgOption['SEARCHABLE'],
            cfgOption['SEARCHABLEDISPLAYCOLUMNS'],
            cfgOption['STATE_VALUE'],
            cfgOption['DATA_SOURCE_LIST'],
            cfgOption['DATA_SOURCE_CONNECTOR'],
            cfgOption['DATA_SOURCE_FILTER'],
            cfgOption['DATA_PRESENTATION'],
            cfgOption['SORTABLE_COLUMNS'],
        ],
        required: [
            cfgOption['NAME'],
            cfgOption['DATA_SOURCE_LIST'],
            cfgOption['DATA_SOURCE_LIST_VALUE'],
            cfgOption['DATA_SOURCE_CONNECTOR'],
            cfgOption['DATA_SOURCE_CONNECTOR_BINDING'],
            cfgOption['DATA_SOURCE_CONNECTOR_TYPE'],
        ],
        attributes: [
            { label: translations.PAGE_BUILDER_classes_attribute_label, value: 'classes' },
            {
                label: translations.PAGE_BUILDER_isRowSelectionDisabled_attribute_label,
                value: 'isRowSelectionDisabled',
            },
            { label: translations.PAGE_BUILDER_radio_attribute_label, value: 'radio' },
            { label: translations.PAGE_BUILDER_borderless_attribute_label, value: 'borderless' },
            { label: translations.PAGE_BUILDER_striped_attribute_label, value: 'striped' },
        ],
    },
    [COMPONENT_TYPE['DATAGRID']]: {
        base: null,
        group: COMPONENT_GROUPS['DATA'],
        type: COMPONENT_TYPE['DATAGRID'],
        componentSuggestContentTypeFilter: ['ContentList'],
        componentSuggestColumnType: columnTypes.multiple,
        runtimeSupport: RUNTIME_VERSION['NEXT'],
        renderFn: Table,
        ui: {
            icon: <GridNine />,
            caption: 'Data Grid',
            shortcut: '---', // Maybe something like CTRL+SHIFT+T?
            description: 'Data Grid component short description.',
            help: null,
        },
        configuration: [
            cfgOption['NAME'],
            cfgOption['LABEL'],
            cfgOption['MULTISELECT'],
            cfgOption['SEARCHABLE'],
            cfgOption['SORTABLE_COLUMNS'],
            cfgOption['SEARCHABLEDISPLAYCOLUMNS'],
            cfgOption['STATE_VALUE'],
            cfgOption['DATA_SOURCE_LIST'],
            cfgOption['DATA_SOURCE_CONNECTOR'],
            cfgOption['DATA_SOURCE_FILTER'],
            cfgOption['DATA_PRESENTATION'],
            cfgOption['EDITABLE_COLUMNS'],
            cfgOption['EXPORT_CSV_BUTTON'],
            cfgOption['CSV_FILE_NAME'],
            cfgOption['COLUMN_COMPONENT_TYPE'],
            cfgOption['PIN_COLUMNS'],
        ],
        required: [
            cfgOption['NAME'],
            cfgOption['DATA_SOURCE_LIST'],
            cfgOption['DATA_SOURCE_LIST_VALUE'],
            cfgOption['DATA_SOURCE_CONNECTOR'],
            cfgOption['DATA_SOURCE_CONNECTOR_BINDING'],
            cfgOption['DATA_SOURCE_CONNECTOR_TYPE'],
        ],
        attributes: [
            { label: translations.PAGE_BUILDER_classes_attribute_label, value: 'classes' },
            {
                label: translations.PAGE_BUILDER_pagination_size_attribute_label,
                value: 'paginationSize',
            },
        ],
    },
    [COMPONENT_TYPE['FILEUPLOAD']]: {
        base: null,
        group: COMPONENT_GROUPS['DATA'],
        type: COMPONENT_TYPE['FILEUPLOAD'],
        // Only a service populates a file upload
        componentSuggestContentTypeFilter: [],
        componentSuggestColumnType: columnTypes.none,
        runtimeSupport: RUNTIME_VERSION['BOTH'], // remove this once we switch to new runtime completely
        renderFn: FileUpload,
        stateValueTypeElementFilter: SYSTEM_TYPE_NAME_ID_MAPPINGS.$File,
        ui: {
            icon: <FileArrowUp />,
            caption: 'File Upload',
            shortcut: '---',
            description: 'Allows users to upload file(s)',
            help: 'https://help.boomi.com/bundle/flow/page/c-flo-Pages_Components_FileUpload.html',
        },
        configuration: [
            cfgOption['NAME'],
            cfgOption['LABEL'],
            cfgOption['MULTISELECT'],
            cfgOption['EDITABLE'],
            cfgOption['AUTO_UPLOAD'],
            cfgOption['FILE_DATA_SOURCE'],
            cfgOption['STATE_VALUE'],
            cfgOption['HINT_VALUE'],
        ],
        required: [cfgOption['NAME']],
        attributes: [
            { label: translations.PAGE_BUILDER_classes_attribute_label, value: 'classes' },
        ],
    },
    [COMPONENT_TYPE['FILES']]: {
        base: null,
        group: COMPONENT_GROUPS['DATA'],
        type: COMPONENT_TYPE['FILES'],
        // Only a service populates a files
        componentSuggestContentTypeFilter: [],
        componentSuggestColumnType: columnTypes.none,
        runtimeSupport: RUNTIME_VERSION['LEGACY'], // remove this once we switch to new runtime completely
        renderFn: Files,
        stateValueTypeElementFilter: SYSTEM_TYPE_NAME_ID_MAPPINGS.$File,
        dataPresentationPropertiesTypeElement: SYSTEM_TYPE_NAME_ID_MAPPINGS.$File,
        ui: {
            icon: <FilesIcon />,
            caption: 'Files',
            shortcut: '---',
            description: 'Display a list of files in a table with download links',
            help: 'https://help.boomi.com/bundle/flow/page/c-flo-Pages_Components_Files.html',
        },
        configuration: [
            cfgOption['NAME'],
            cfgOption['LABEL'],
            cfgOption['EDITABLE'],
            cfgOption['MULTISELECT'],
            cfgOption['SEARCHABLE'],
            cfgOption['SEARCHABLEDISPLAYCOLUMNS'],
            cfgOption['FILE_DATA_SOURCE'],
            cfgOption['STATE_VALUE'],
            cfgOption['DATA_PRESENTATION'],
        ],
        required: [cfgOption['NAME']],
        attributes: [
            { label: translations.PAGE_BUILDER_classes_attribute_label, value: 'classes' },
        ],
    },
    [COMPONENT_TYPE['TILES']]: {
        base: null,
        group: COMPONENT_GROUPS['DATA'],
        type: COMPONENT_TYPE['TILES'],
        componentSuggestContentTypeFilter: ['ContentList'],
        componentSuggestColumnType: columnTypes.none,
        runtimeSupport: RUNTIME_VERSION['BOTH'], // remove this once we switch to new runtime completely
        renderFn: Tiles,
        ui: {
            icon: <GridFour />,
            caption: 'Tiles',
            shortcut: '---', // Maybe something like CTRL+SHIFT+T?
            description: 'Display a list of items as a series of tiles',
            help: null,
        },
        configuration: [
            cfgOption['NAME'],
            cfgOption['LABEL'],
            cfgOption['MULTISELECT'],
            cfgOption['SEARCHABLE'],
            cfgOption['SEARCHABLEDISPLAYCOLUMNS'],
            cfgOption['STATE_VALUE'],
            cfgOption['DATA_SOURCE_LIST'],
            cfgOption['DATA_SOURCE_CONNECTOR'],
            cfgOption['DATA_PRESENTATION'],
            cfgOption['DATA_SOURCE_FILTER'],
        ],
        required: [
            cfgOption['NAME'],
            cfgOption['DATA_SOURCE_LIST'],
            cfgOption['DATA_SOURCE_LIST_VALUE'],
            cfgOption['DATA_SOURCE_CONNECTOR'],
            cfgOption['DATA_SOURCE_CONNECTOR_TYPE'],
            cfgOption['DATA_SOURCE_CONNECTOR_BINDING'],
        ],
        attributes: [
            { label: translations.PAGE_BUILDER_classes_attribute_label, value: 'classes' },
        ],
    },
    [COMPONENT_TYPE['CHARTBAR']]: {
        base: null,
        group: COMPONENT_GROUPS['CHARTS'],
        type: COMPONENT_TYPE['CHARTBAR'],
        componentSuggestContentTypeFilter: ['ContentList'],
        componentSuggestColumnType: columnTypes.none,
        runtimeSupport: RUNTIME_VERSION['BOTH'], // remove this once we switch to new runtime completely
        renderFn: BarChartComponent,
        ui: {
            icon: <ChartBar />,
            caption: 'Bar Chart',
            shortcut: '---',
            description: 'Display a bar chart.',
            help: null,
            canGoBack: true,
            heading: 'Configure Dataset',
        },
        configuration: [
            cfgOption['NAME'],
            cfgOption['LABEL'],
            cfgOption['STATE_VALUE'],
            cfgOption['DATA_SOURCE_LIST'],
            cfgOption['DATA_SOURCE_CONNECTOR'],
            cfgOption['DATA_PRESENTATION'],
        ],
        required: [
            cfgOption['NAME'],
            cfgOption['DATA_SOURCE_LIST'],
            cfgOption['DATA_SOURCE_LIST_VALUE'],
            cfgOption['DATA_SOURCE_CONNECTOR'],
            cfgOption['DATA_SOURCE_CONNECTOR_TYPE'],
            cfgOption['DATA_SOURCE_CONNECTOR_BINDING'],
        ],
        attributes: [
            { label: translations.PAGE_BUILDER_classes_attribute_label, value: 'classes' },
            { label: translations.PAGE_BUILDER_label_attribute_label, value: 'label' },
        ],
    },
    [COMPONENT_TYPE['CHARTLINE']]: {
        base: null,
        group: COMPONENT_GROUPS['CHARTS'],
        type: COMPONENT_TYPE['CHARTLINE'],
        componentSuggestContentTypeFilter: ['ContentList'],
        componentSuggestColumnType: columnTypes.none,
        runtimeSupport: RUNTIME_VERSION['BOTH'], // remove this once we switch to new runtime completely
        renderFn: LineChartComponent,
        ui: {
            icon: <ChartLine />,
            caption: 'Line Chart',
            shortcut: '---',
            description: 'Display a line chart.',
            help: null,
            canGoBack: true,
            heading: 'Configure Dataset',
        },
        configuration: [
            cfgOption['NAME'],
            cfgOption['LABEL'],
            cfgOption['STATE_VALUE'],
            cfgOption['DATA_SOURCE_LIST'],
            cfgOption['DATA_SOURCE_CONNECTOR'],
            cfgOption['DATA_PRESENTATION'],
        ],
        required: [
            cfgOption['NAME'],
            cfgOption['DATA_SOURCE_LIST'],
            cfgOption['DATA_SOURCE_LIST_VALUE'],
            cfgOption['DATA_SOURCE_CONNECTOR'],
            cfgOption['DATA_SOURCE_CONNECTOR_TYPE'],
            cfgOption['DATA_SOURCE_CONNECTOR_BINDING'],
        ],
        attributes: [
            { label: translations.PAGE_BUILDER_classes_attribute_label, value: 'classes' },
            { label: translations.PAGE_BUILDER_label_attribute_label, value: 'label' },
        ],
    },
    [COMPONENT_TYPE['CHARTPIE']]: {
        base: null,
        group: COMPONENT_GROUPS['CHARTS'],
        type: COMPONENT_TYPE['CHARTPIE'],
        componentSuggestContentTypeFilter: ['ContentList'],
        componentSuggestColumnType: columnTypes.none,
        runtimeSupport: RUNTIME_VERSION['BOTH'], // remove this once we switch to new runtime completely
        renderFn: PieChartComponent,
        ui: {
            icon: <ChartPie />,
            caption: 'Pie Chart',
            shortcut: '---',
            description: 'Display a pie chart.',
            help: null,
            canGoBack: true,
            heading: 'Configure Dataset',
        },
        configuration: [
            cfgOption['NAME'],
            cfgOption['LABEL'],
            cfgOption['STATE_VALUE'],
            cfgOption['DATA_SOURCE_LIST'],
            cfgOption['DATA_SOURCE_CONNECTOR'],
            cfgOption['DATA_PRESENTATION'],
        ],
        required: [
            cfgOption['NAME'],
            cfgOption['DATA_SOURCE_LIST'],
            cfgOption['DATA_SOURCE_LIST_VALUE'],
            cfgOption['DATA_SOURCE_CONNECTOR'],
            cfgOption['DATA_SOURCE_CONNECTOR_TYPE'],
            cfgOption['DATA_SOURCE_CONNECTOR_BINDING'],
        ],
        attributes: [
            { label: translations.PAGE_BUILDER_classes_attribute_label, value: 'classes' },
        ],
    },
    [COMPONENT_TYPE['CHARTDOUGHNUT']]: {
        base: null,
        group: COMPONENT_GROUPS['CHARTS'],
        type: COMPONENT_TYPE['CHARTDOUGHNUT'],
        componentSuggestContentTypeFilter: ['ContentList'],
        componentSuggestColumnType: columnTypes.none,
        runtimeSupport: RUNTIME_VERSION['BOTH'], // remove this once we switch to new runtime completely
        renderFn: DoughnutChartComponent,
        ui: {
            icon: <Disc />,
            caption: 'Doughnut Chart',
            shortcut: '---',
            description: 'Display a doughnut chart.',
            help: null,
            canGoBack: true,
            heading: 'Configure Dataset',
        },
        configuration: [
            cfgOption['NAME'],
            cfgOption['LABEL'],
            cfgOption['STATE_VALUE'],
            cfgOption['DATA_SOURCE_LIST'],
            cfgOption['DATA_SOURCE_CONNECTOR'],
            cfgOption['DATA_PRESENTATION'],
        ],
        required: [
            cfgOption['NAME'],
            cfgOption['DATA_SOURCE_LIST'],
            cfgOption['DATA_SOURCE_LIST_VALUE'],
            cfgOption['DATA_SOURCE_CONNECTOR'],
            cfgOption['DATA_SOURCE_CONNECTOR_TYPE'],
            cfgOption['DATA_SOURCE_CONNECTOR_BINDING'],
        ],
        attributes: [
            { label: translations.PAGE_BUILDER_classes_attribute_label, value: 'classes' },
        ],
    },
    [COMPONENT_TYPE['CHARTPOLAR']]: {
        base: null,
        group: COMPONENT_GROUPS['CHARTS'],
        type: COMPONENT_TYPE['CHARTPOLAR'],
        componentSuggestContentTypeFilter: ['ContentList'],
        componentSuggestColumnType: columnTypes.none,
        runtimeSupport: RUNTIME_VERSION['BOTH'], // remove this once we switch to new runtime completely
        renderFn: PolarChartComponent,
        ui: {
            icon: <Hexagon />,
            caption: 'Polar Chart',
            shortcut: '---',
            description: 'Display a polar chart.',
            help: null,
            canGoBack: true,
            heading: 'Configure Dataset',
        },
        configuration: [
            cfgOption['NAME'],
            cfgOption['LABEL'],
            cfgOption['STATE_VALUE'],
            cfgOption['DATA_SOURCE_LIST'],
            cfgOption['DATA_SOURCE_CONNECTOR'],
            cfgOption['DATA_PRESENTATION'],
        ],
        required: [
            cfgOption['NAME'],
            cfgOption['DATA_SOURCE_LIST'],
            cfgOption['DATA_SOURCE_LIST_VALUE'],
            cfgOption['DATA_SOURCE_CONNECTOR'],
            cfgOption['DATA_SOURCE_CONNECTOR_TYPE'],
            cfgOption['DATA_SOURCE_CONNECTOR_BINDING'],
        ],
        attributes: [
            { label: translations.PAGE_BUILDER_classes_attribute_label, value: 'classes' },
        ],
    },
    [COMPONENT_TYPE['HIDDEN']]: {
        base: null,
        group: COMPONENT_GROUPS['CONTENT'],
        type: COMPONENT_TYPE['HIDDEN'],
        componentSuggestContentTypeFilter: [],
        componentSuggestColumnType: columnTypes.none,
        runtimeSupport: RUNTIME_VERSION['LEGACY'], // remove this once we switch to new runtime completely
        renderFn: HiddenInput,
        ui: {
            icon: <EyeSlash />,
            caption: 'Hidden',
            shortcut: '---',
            description: 'Do not display anything.',
            help: 'https://help.boomi.com/bundle/flow/page/c-flo-Pages_Components_Hidden.html',
        },
        configuration: [cfgOption['NAME'], cfgOption['STATE_VALUE']],
        required: [cfgOption['NAME'], cfgOption['STATE_VALUE']],
        attributes: [
            { label: translations.PAGE_BUILDER_classes_attribute_label, value: 'classes' },
        ],
    },
    [COMPONENT_TYPE['PRESENTATION']]: {
        base: null,
        group: COMPONENT_GROUPS['CONTENT'],
        type: COMPONENT_TYPE['PRESENTATION'],
        componentSuggestContentTypeFilter: ['ContentString', 'ContentContent'],
        componentSuggestColumnType: columnTypes.none,
        runtimeSupport: RUNTIME_VERSION['BOTH'], // remove this once we switch to new runtime completely
        renderFn: Presentation,
        ui: {
            icon: <PresentationIcon />,
            caption: 'Presentation',
            shortcut: '---',
            description: 'Display rich text content.',
            help: 'https://help.boomi.com/bundle/flow/page/c-flo-Pages_Components_Presentation.html',
        },
        configuration: [cfgOption['NAME'], cfgOption['LABEL'], cfgOption['CONTENT']],
        required: [cfgOption['NAME']],
        attributes: [
            { label: translations.PAGE_BUILDER_classes_attribute_label, value: 'classes' },
        ],
    },
    [COMPONENT_TYPE['LIST']]: {
        base: null,
        group: COMPONENT_GROUPS['CONTENT'],
        type: COMPONENT_TYPE['LIST'],
        componentSuggestContentTypeFilter: ['ContentList'],
        componentSuggestColumnType: columnTypes.single,
        runtimeSupport: RUNTIME_VERSION['BOTH'], // remove this once we switch to new runtime completely
        renderFn: List,
        ui: {
            icon: <ListNumbers />,
            caption: 'List',
            shortcut: '---',
            description: 'Display a list of items as a bullet or numbered list.',
            help: 'https://help.boomi.com/bundle/flow/page/c-flo-Pages_Components_List.html',
        },
        configuration: [
            cfgOption['NAME'],
            cfgOption['LABEL'],
            cfgOption['DATA_SOURCE_LIST'],
            cfgOption['DATA_SOURCE_CONNECTOR'],
            cfgOption['DATA_SOURCE_FILTER'],
            cfgOption['DATA_PRESENTATION'],
        ],
        required: [
            cfgOption['NAME'],
            cfgOption['DATA_SOURCE_LIST'],
            cfgOption['DATA_SOURCE_LIST_VALUE'],
            cfgOption['DATA_SOURCE_CONNECTOR'],
            cfgOption['DATA_SOURCE_CONNECTOR_BINDING'],
            cfgOption['DATA_SOURCE_CONNECTOR_TYPE'],
        ],
        attributes: [
            { label: translations.PAGE_BUILDER_classes_attribute_label, value: 'classes' },
            { label: translations.PAGE_BUILDER_ordered_attribute_label, value: 'ordered' },
        ],
    },
    [COMPONENT_TYPE['IFRAME']]: {
        base: {
            height: 500,
        },
        group: COMPONENT_GROUPS['CONTENT'],
        type: COMPONENT_TYPE['IFRAME'],
        componentSuggestContentTypeFilter: [],
        componentSuggestColumnType: columnTypes.none,
        runtimeSupport: RUNTIME_VERSION['NEXT'], // remove this once we switch to new runtime completely
        renderFn: Iframe,
        ui: {
            icon: <FileHtml />,
            caption: 'Iframe',
            shortcut: '---',
            description: 'Display a web page.',
            help: 'https://help.boomi.com/bundle/flow/page/c-flo-Pages_Components_Iframe.html',
        },
        configuration: [
            cfgOption['NAME'],
            cfgOption['LABEL'],
            cfgOption['SOURCE'],
            cfgOption['WIDTH'],
            cfgOption['HEIGHT'],
        ],
        required: [cfgOption['NAME'], cfgOption['SOURCE']],
        attributes: [],
    },
    [COMPONENT_TYPE['OUTCOMES']]: {
        base: null,
        group: COMPONENT_GROUPS['CONTENT'],
        type: COMPONENT_TYPE['OUTCOMES'],
        // Outcomes don't read in values
        componentSuggestContentTypeFilter: [],
        componentSuggestColumnType: columnTypes.none,
        runtimeSupport: RUNTIME_VERSION['BOTH'], // remove this once we switch to new runtime completely
        renderFn: Outcomes,
        ui: {
            icon: <FlowArrow />,
            caption: 'Outcomes',
            shortcut: '---',
            description: 'Display any associated outcome buttons.',
            help: 'https://help.boomi.com/bundle/flow/page/c-flo-Pages_Components_Outcomes.html',
        },
        configuration: [cfgOption['NAME'], cfgOption['LABEL']],
        required: [cfgOption['NAME']],
        attributes: [
            { label: translations.PAGE_BUILDER_classes_attribute_label, value: 'classes' },
            { label: translations.PAGE_BUILDER_justify_attribute_label, value: 'justify' },
            { label: translations.PAGE_BUILDER_group_attribute_label, value: 'group' },
            { label: translations.PAGE_BUILDER_columns_attribute_label, value: 'columns' },
            { label: translations.PAGE_BUILDER_size_attribute_label, value: 'size' },
            {
                label: translations.PAGE_BUILDER_outcome_classes_attribute_label,
                value: 'outcomeClasses',
            },
        ],
    },
    [COMPONENT_TYPE['IMAGE']]: {
        base: null,
        group: COMPONENT_GROUPS['CONTENT'],
        type: COMPONENT_TYPE['IMAGE'],
        // Images don't read in values
        componentSuggestContentTypeFilter: [],
        componentSuggestColumnType: columnTypes.none,
        runtimeSupport: RUNTIME_VERSION['LEGACY'], // remove this once we switch to new runtime completely
        renderFn: Image,
        ui: {
            icon: <ImageIcon />,
            caption: 'Image',
            shortcut: '---',
            description: 'Display an image.',
            help: 'https://help.boomi.com/bundle/flow/page/c-flo-Pages_Components_Image.html',
        },
        configuration: [
            cfgOption['NAME'],
            cfgOption['LABEL'],
            cfgOption['IMAGE_URI'],
            cfgOption['WIDTH'],
            cfgOption['HEIGHT'],
        ],
        required: [cfgOption['NAME'], cfgOption['IMAGE_URI']],
        attributes: [
            { label: translations.PAGE_BUILDER_classes_attribute_label, value: 'classes' },
            {
                label: translations.PAGE_BUILDER_crossorigin_attribute_label,
                value: 'crossorigin',
            },
            { label: translations.PAGE_BUILDER_ismap_attribute_label, value: 'ismap' },
            {
                label: translations.PAGE_BUILDER_loading_attribute_label,
                value: 'loading',
            },
            { label: translations.PAGE_BUILDER_longdesc_attribute_label, value: 'longdesc' },
            {
                label: translations.PAGE_BUILDER_referrerpolicy_attribute_label,
                value: 'referrerpolicy',
            },
            { label: translations.PAGE_BUILDER_sizes_attribute_label, value: 'sizes' },
            {
                label: translations.PAGE_BUILDER_srcset_attribute_label,
                value: 'srcset',
            },
            { label: translations.PAGE_BUILDER_usemap_attribute_label, value: 'usemap' },
        ],
    },
    [COMPONENT_TYPE['PDF']]: {
        base: null,
        group: COMPONENT_GROUPS['DATA'],
        type: COMPONENT_TYPE['PDF'],
        // Pdf downloader not supported yet
        componentSuggestContentTypeFilter: [],
        componentSuggestColumnType: columnTypes.none,
        runtimeSupport: RUNTIME_VERSION['BOTH'], // remove this once we switch to new runtime completely
        renderFn: Pdf,
        ui: {
            icon: <FilePdf />,
            caption: 'PDF',
            shortcut: '---',
            description: 'Displays a link to a PDF document generated by the PDF connector.',
            help: 'https://help.boomi.com/bundle/flow/page/flo-PDF_Component.html',
        },
        configuration: [cfgOption['NAME'], cfgOption['LABEL'], cfgOption['STATE_VALUE']],
        required: [cfgOption['NAME'], cfgOption['STATE_VALUE']],
        attributes: [
            { label: translations.PAGE_BUILDER_classes_attribute_label, value: 'classes' },
        ],
    },
    [COMPONENT_TYPE['PAYMENTCARD']]: {
        base: null,
        group: COMPONENT_GROUPS['INPUT'],
        type: COMPONENT_TYPE['PAYMENTCARD'],
        // Payment card not supported yet
        componentSuggestContentTypeFilter: [],
        componentSuggestColumnType: columnTypes.none,
        runtimeSupport: RUNTIME_VERSION['NEXT'], // remove this once we switch to new runtime completely
        renderFn: PaymentCard,
        stateValueTypeElementFilter: SYSTEM_TYPE_NAME_ID_MAPPINGS['$Payment Card'],
        ui: {
            icon: <CreditCard />,
            caption: 'Payment Card',
            shortcut: '---',
            description: 'Get payment card (credit, debit, etc.) input from the user.',
            help: '<insert_docs_link_here>',
        },
        configuration: [
            cfgOption['NAME'],
            cfgOption['LABEL'],
            cfgOption['EDITABLE'],
            cfgOption['STATE_VALUE'],
        ],
        required: [cfgOption['NAME'], cfgOption['STATE_VALUE']],
        attributes: [],
    },
    [COMPONENT_TYPE['SIGNATUREPAD']]: {
        base: null,
        group: COMPONENT_GROUPS['INPUT'],
        type: COMPONENT_TYPE['SIGNATUREPAD'],
        componentSuggestContentTypeFilter: ['ContentString'],
        componentSuggestColumnType: columnTypes.none,
        runtimeSupport: RUNTIME_VERSION['NEXT'],
        renderFn: SignaturePad,
        stateValueContentTypeFilter: 'ContentString',
        ui: {
            icon: <Signature />,
            caption: 'Signature Pad',
            shortcut: '---',
            description: 'Get handwritten input from the user.',
            help: '<insert_docs_link_here>',
        },
        configuration: [
            cfgOption['NAME'],
            cfgOption['LABEL'],
            cfgOption['EDITABLE'],
            cfgOption['STATE_VALUE'],
        ],
        required: [cfgOption['NAME'], cfgOption['STATE_VALUE']],
        attributes: [],
    },
    [COMPONENT_TYPE['CODEGENERATOR']]: {
        base: null,
        group: COMPONENT_GROUPS['CONTENT'],
        type: COMPONENT_TYPE['CODEGENERATOR'],
        componentSuggestContentTypeFilter: ['ContentString'],
        componentSuggestColumnType: columnTypes.none,
        runtimeSupport: RUNTIME_VERSION['NEXT'],
        renderFn: CodeGenerator,
        stateValueContentTypeFilter: 'ContentString',
        ui: {
            icon: <QrCode />,
            caption: 'Code Generator',
            shortcut: '---',
            description: 'Generate and display QR codes',
            help: '<insert_docs_link_here>',
        },
        configuration: [cfgOption['NAME'], cfgOption['LABEL'], cfgOption['STATE_VALUE']],
        required: [cfgOption['NAME'], cfgOption['STATE_VALUE']],
        attributes: [],
    },
    [COMPONENT_TYPE['CODESCANNER']]: {
        base: null,
        group: COMPONENT_GROUPS['INPUT'],
        type: COMPONENT_TYPE['CODESCANNER'],
        componentSuggestContentTypeFilter: ['ContentString'],
        componentSuggestColumnType: columnTypes.none,
        runtimeSupport: RUNTIME_VERSION['NEXT'],
        renderFn: CodeScanner,
        stateValueContentTypeFilter: 'ContentString',
        ui: {
            icon: <Scan />,
            caption: 'Code Scanner',
            shortcut: '---',
            description: 'Enable the user to scan QR codes',
            help: '<insert_docs_link_here>',
        },
        configuration: [
            cfgOption['NAME'],
            cfgOption['LABEL'],
            cfgOption['EDITABLE'],
            cfgOption['STATE_VALUE'],
        ],
        required: [cfgOption['NAME'], cfgOption['STATE_VALUE']],
        attributes: [],
    },
    [COMPONENT_TYPE['AICHAT']]: {
        base: {
            isMultiSelect: true,
            objectDataRequest: {
                typeElementBindingId: '00000000-0000-0000-0000-000000000000',
                typeElementId: SYSTEM_TYPE_NAME_ID_MAPPINGS['$Chat Message'],
                listFilter: null,
            },
        },
        group: COMPONENT_GROUPS['INPUT'],
        type: COMPONENT_TYPE['AICHAT'],
        componentSuggestContentTypeFilter: ['ContentList'],
        componentSuggestColumnType: columnTypes.none,
        runtimeSupport: RUNTIME_VERSION['NEXT'],
        renderFn: AIChat,
        stateValueContentTypeFilter: 'ContentList',
        stateValueTypeElementFilter: SYSTEM_TYPE_NAME_ID_MAPPINGS['$Chat Message'],
        ui: {
            icon: <ChatCenteredDots />,
            caption: 'AI Chat',
            shortcut: '---',
            description: 'Converse with an AI chat model',
            help: '<insert_docs_link_here>',
        },
        configuration: [
            cfgOption['NAME'],
            cfgOption['LABEL'],
            cfgOption['HINT_VALUE'],
            cfgOption['STATE_VALUE'],
            cfgOption['DATA_SOURCE_2'],
        ],
        required: [cfgOption['NAME'], cfgOption['DATA_SOURCE_2']],
        attributes: [
            { label: translations.PAGE_BUILDER_assistant_attribute_label, value: 'assistantLabel' },
            { label: translations.PAGE_BUILDER_user_attribute_label, value: 'userLabel' },
        ],
    },
    [COMPONENT_TYPE['UNKNOWN']]: {
        base: null,
        group: null,
        type: COMPONENT_TYPE['UNKNOWN'],
        componentSuggestContentTypeFilter: [],
        componentSuggestColumnType: columnTypes.none,
        runtimeSupport: RUNTIME_VERSION['BOTH'], // remove this once we switch to new runtime completely
        renderFn: Presentation,
        ui: {
            icon: <CircleWavyQuestion />,
            caption: '',
            shortcut: '---',
            description: '',
            help: '',
        },
        configuration: Object.values(cfgOption),
        required: [],
    },
};
