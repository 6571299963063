import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMapElement } from '../contextProviders/MapElementProvider';
import { getElements } from '../../../../config/graphElements';
import {
    generateRouteUrl,
    getByID,
    getSharedStyles,
    isNullOrEmpty,
    stringContains,
} from '../../../../utils';
import { getPageList, savePage } from '../../../../sources/page';
import { ElementType, type PageElementRequestAPI, type SelectOption } from '../../../../types';
import { MAP_ELEMENT_TYPES, TAB_TYPES } from '../../../../constants';
import type { MapElementRequestAPI } from '../../../../sources/graph';
import { getFlowSharedElements } from '../../../../sources/flow';
import NameInput from '../common/NameInput';
import FormGroup from '../../../generic/FormGroup';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash.debounce';
import type { SingleValue } from 'react-select';
import ButtonDefault from '../../../buttons/ButtonDefault';
import Glyphicon from '../../../generic/Glyphicon';
import ButtonPrimary from '../../../buttons/ButtonPrimary';
import OutcomeList from '../common/outcomes/OutcomeList';
import MessageActionsList from '../common/messageActions/MessageActionsList';
import ListenerList from '../common/listeners/ListenerList';
import NavigationOverrideList from '../common/navigationOverrides/NavigationOverrideList';
import WaitList from '../common/wait/WaitList';
import HTMLInput from '../../../generic/HTMLEditor/HTMLInput';
import Footer from '../common/Footer';
import translations from '../../../../translations';
import ModalBody from '../../../generic/modal/ModalBody';
import ModalFooter from '../../../generic/modal/ModalFooter';

const PageConfiguration = () => {
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [onlyShowPagesInFlow, setOnlyShowPagesInFlow] = useState(true);
    const [selectedPage, setSelectedPage] = useState<SelectOption | null>(null);

    const [queryPagesWithinFlowIsLoading, setQueryPagesWithinFlowIsLoading] = useState(false);
    const [queryAllFlowsIsLoading, setQueryAllFlowsIsLoading] = useState(false);

    const navigate = useNavigate();

    const {
        tenantId,
        flowId,
        mapElement,
        setMapElement,
        onUpdateName,
        onUpdateTitle,
        onSaveMapElement,
        onClose,
        onUpdateNotAuthorizedMessage,
        onUpdateComments,
        notifyError,
    } = useMapElement();

    const { pageElementId, pageElementDeveloperName } = mapElement;

    useEffect(() => {
        if (pageElementId && pageElementDeveloperName) {
            setSelectedPage({
                value: pageElementId,
                label: pageElementDeveloperName,
            });
        } else {
            setSelectedPage(null);
        }
    }, [pageElementId, pageElementDeveloperName]);

    const { developerName, developerSummary, title } = mapElement;

    const isPageElementNameValid = !isNullOrEmpty(developerName);
    const isSelectedPageValid = !isNullOrEmpty(selectedPage);
    const graphElements = getElements();

    const createNewPage = () => {
        const newPagePayload: PageElementRequestAPI = {
            id: null,
            developerName: isNullOrEmpty(developerName) ? 'New Page' : developerName,
            developerSummary: null,
            elementType: ElementType.Page,
            pageContainers: [
                {
                    id: null,
                    developerName: 'Main',
                    containerType: 'VERTICAL_FLOW',
                },
            ],
            pageConditions: null,
            pageComponents: null,
        };
        return savePage(newPagePayload);
    };

    const onNewPageClick = async () => {
        try {
            const page = await createNewPage();

            onSelectPage({ value: page.id, label: page.developerName });

            const routeOptions = {
                tabType: TAB_TYPES.page,
                tenantId,
                options: {
                    pageName: page.developerName,
                    elementId: page.id,
                },
            };

            const route = generateRouteUrl(routeOptions);

            navigate(route);
        } catch (error) {
            notifyError(error);
        }
    };

    const onEditPageClick = () => {
        if (selectedPage?.value) {
            const route = generateRouteUrl({
                tabType: TAB_TYPES.page,
                tenantId,
                options: {
                    elementId: selectedPage.value,
                },
            });

            navigate(route);
        }
    };

    const onSelectPage = (option: SingleValue<SelectOption>) => {
        setMapElement({
            ...mapElement,
            pageElementId: option?.value ?? null,
            pageElementDeveloperName: option?.label ?? null,
        });
    };

    const onSave = () => {
        setHasSubmitted(true);
        if (isPageElementNameValid && isSelectedPageValid) {
            onSaveMapElement(mapElement as MapElementRequestAPI);
        }
    };

    const queryAllPages = debounce(
        async (searchQuery: string, callback: (options: SelectOption[]) => void) => {
            setQueryAllFlowsIsLoading(true);

            try {
                const response = await getPageList({
                    search: searchQuery,
                    page: 1,
                    pageSize: 100,
                    orderBy: 'developerName',
                    orderDirection: 'ASC',
                });

                callback(
                    response.items.map<SelectOption>((r) => ({
                        value: r.id,
                        label: r.developerName,
                    })),
                );
            } catch (error) {
                notifyError(error);
            } finally {
                setQueryAllFlowsIsLoading(false);
            }
        },
        500,
    ) as (inputValue: string, callback: (options: SelectOption[]) => void) => void;

    const queryPagesWithinFlow = debounce(
        async (searchQuery: string, callback: (options: SelectOption[]) => void) => {
            setQueryPagesWithinFlowIsLoading(true);

            try {
                const response = await getFlowSharedElements(flowId, 'page');
                response
                    .filter((page) => stringContains(page.developerName, searchQuery))
                    .sort((a, b) => a.developerName.localeCompare(b.developerName));

                callback(
                    response.map<SelectOption>((r) => ({
                        value: r.id,
                        label: r.developerName,
                    })),
                );
            } catch (error) {
                notifyError(error);
            } finally {
                setQueryPagesWithinFlowIsLoading(false);
            }
        },
        500,
    ) as (inputValue: string, callback: (options: SelectOption[]) => void) => void;

    const renderBody = () => (
        <>
            <NameInput
                isValid={isPageElementNameValid}
                showValidation={hasSubmitted}
                id="page-element-name"
                name={mapElement.developerName ?? ''}
                onUpdateName={onUpdateName}
            />
            {mapElement.elementType.toLowerCase() === MAP_ELEMENT_TYPES.modal && (
                <FormGroup label="Title" htmlFor={'title'}>
                    <input
                        id={'title'}
                        value={title ?? ''}
                        onChange={(e) => onUpdateTitle(e.target.value)}
                        maxLength={255}
                        className="form-control form-control-width"
                        type="text"
                    />
                </FormGroup>
            )}
            <FormGroup
                isRequired
                label="Page Layout"
                htmlFor="select-page"
                className={'margin-top'}
                isValid={isSelectedPageValid}
                validationMessage={'Please select a Page Layout.'}
                showValidation={hasSubmitted}
            >
                <div className="flex">
                    {onlyShowPagesInFlow ? (
                        <AsyncSelect
                            key="queryFlow"
                            inputId="select-page"
                            className="flex-grow form-control-width"
                            placeholder="Search"
                            loadOptions={queryPagesWithinFlow}
                            onChange={onSelectPage}
                            value={selectedPage}
                            isLoading={queryPagesWithinFlowIsLoading}
                            isMulti={false}
                            isSearchable={true}
                            isClearable={true}
                            defaultOptions
                            styles={getSharedStyles<{ label: string; value: string }, true>()}
                        />
                    ) : (
                        <AsyncSelect
                            key="queryAll"
                            inputId="select-page"
                            className="flex-grow form-control-width"
                            placeholder="Search"
                            loadOptions={queryAllPages}
                            onChange={onSelectPage}
                            value={selectedPage}
                            isLoading={queryAllFlowsIsLoading}
                            isMulti={false}
                            isSearchable={true}
                            isClearable={true}
                            defaultOptions
                            styles={getSharedStyles<{ label: string; value: string }, true>()}
                        />
                    )}

                    {isSelectedPageValid && (
                        <ButtonDefault onClick={onEditPageClick} className={'margin-left-sml'}>
                            <Glyphicon glyph={'pencil'} /> Edit Layout
                        </ButtonDefault>
                    )}
                    <ButtonPrimary onClick={onNewPageClick} className={'margin-left'}>
                        <Glyphicon glyph={'plus'} />
                        New Layout
                    </ButtonPrimary>
                </div>
            </FormGroup>
            <div className={'from-group'}>
                <label>
                    <input
                        type={'checkbox'}
                        checked={onlyShowPagesInFlow}
                        onChange={({ target: { checked } }) => {
                            setOnlyShowPagesInFlow(checked);
                        }}
                    />
                    Only show Page Layouts already being used in this Flow
                </label>
            </div>
            <OutcomeList />
            <MessageActionsList />
            <ListenerList />
            <NavigationOverrideList />
            <WaitList />
            <h4>Feedback</h4>
            <FormGroup label="Message to show users that are not authorized to take action">
                <HTMLInput
                    contentValue={mapElement.notAuthorizedMessage ?? ''}
                    onChange={(userContent) => onUpdateNotAuthorizedMessage(userContent)}
                    hasLinkPlugin={true}
                    hasImagePlugin={true}
                    hasValuePickerPlugin={true}
                    height={250}
                />
            </FormGroup>
            <FormGroup
                htmlFor="message-comments"
                label={`Comments about this ${
                    getByID(mapElement.elementType.toLowerCase(), graphElements)?.name ?? ''
                }`}
            >
                <textarea
                    id="message-comments"
                    value={developerSummary ?? ''}
                    className="form-control form-textarea"
                    onChange={({ target: { value } }) => onUpdateComments(value)}
                />
            </FormGroup>
        </>
    );

    const renderFooter = () => {
        return (
            <Footer
                save={onSave}
                saveButtonText={translations.GRAPH_config_panel_save}
                cancel={onClose}
            />
        );
    };

    return (
        <>
            <ModalBody>{renderBody()}</ModalBody>
            <ModalFooter>{renderFooter()}</ModalFooter>
        </>
    );
};

export default PageConfiguration;
