import { useState } from 'react';
import { type ConnectedProps, connect } from 'react-redux';
import { notifyError, notifySuccess } from '../../../js/actions/reduxActions/notification';
import { fetchUsers } from '../../../js/actions/reduxActions/organizations';
import { removeUser } from '../../sources/organization';
import translations from '../../translations';
import { resolveOrgUserRole } from '../../utils/organization';
import { stringReplace } from '../../utils/string';
import { useAuth } from '../AuthProvider';
import ConfirmModal from '../generic/modal/ConfirmModal';

type LeaveOrgProps = {
    container: HTMLElement | null | undefined;
} & ConnectedProps<typeof connector>;

const connector = connect(null, {
    notifyError,
    notifySuccess,
    fetchUsers,
});

const LeaveOrg = ({ fetchUsers, notifyError, notifySuccess, container }: LeaveOrgProps) => {
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { user, tenant, fetchUser } = useAuth();

    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleHideModal = () => {
        setShowModal(false);
    };

    const handleLeaveConfirm = async () => {
        if (!user?.id || !tenant) {
            return;
        }

        try {
            setIsLoading(true);

            await removeUser({
                userId: user.id,
            });

            // Reload the current user in case they have been removed from the current organization
            await fetchUser();

            if (resolveOrgUserRole(user, tenant).isUserAdmin) {
                await fetchUsers();
            }

            notifySuccess(
                stringReplace(translations.FORG_left_confirmation, {
                    user: user.email,
                }),
            );

            handleHideModal();
        } catch (error) {
            notifyError(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <button className="btn btn-danger" onClick={handleShowModal} type="button">
                {translations.FORG_leave_organization_button_label}
            </button>
            <ConfirmModal
                show={showModal}
                onCancel={handleHideModal}
                onConfirm={handleLeaveConfirm}
                title={translations.FORG_leave_confirmation_title}
                messages={[translations.FORG_leave_confirmation]}
                container={container}
                isInProgress={isLoading}
            />
        </>
    );
};

export default connector(LeaveOrg);
