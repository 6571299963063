import { useEffect, useState } from 'react';
import Select from 'react-select';
import FormGroup from '../../../../../../../../generic/FormGroup';
import type { ListFilter, ColumnOption } from '../../../../../../../../../types';
import { getSharedStyles } from '../../../../../../../../../utils/select';

const orderDirections = [
    { value: 'ASC', label: 'Ascending' },
    { value: 'DESC', label: 'Descending' },
];

interface Props {
    columns: ColumnOption[];
    dataSourceFilter: ListFilter | null;
    updateDataSourceFilter: (filter: ListFilter) => void;
}

const FilterWhereGeneralConfig = ({ columns, dataSourceFilter, updateDataSourceFilter }: Props) => {
    const { limit, orderByDirectionType, orderByTypeElementPropertyId } = dataSourceFilter || {};

    const column = orderByTypeElementPropertyId
        ? columns.filter((option) => option.value === orderByTypeElementPropertyId)[0]
        : null;

    const direction = orderByDirectionType
        ? orderDirections.filter((option) => option.value === orderByDirectionType)[0]
        : null;

    const [orderColumn, setOrderColumn] = useState(column);
    const [orderDirection, setOrderDirection] = useState(direction);
    const [noOfRecords, setNoOfRecords] = useState(limit);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        setOrderColumn(column);
        setOrderDirection(direction);
        setNoOfRecords(limit);
    }, [column, dataSourceFilter, direction, limit]);

    const updateOrderColumn = (selectedColumn: { label: string; value: string } | null) => {
        if (selectedColumn !== column && selectedColumn?.value && dataSourceFilter) {
            const updated = {
                ...dataSourceFilter,
                orderByTypeElementPropertyId: selectedColumn?.value,
            };
            updateDataSourceFilter(updated);
        }
    };

    const updateOrderDirection = (selectedSort: { label: string; value: string } | null) => {
        if (selectedSort !== direction && selectedSort?.value && dataSourceFilter) {
            const updated = {
                ...dataSourceFilter,
                orderByDirectionType: selectedSort?.value,
            };
            updateDataSourceFilter(updated);
        }
    };

    const updateNoOfRecords = (selectLimit: number) => {
        if (selectLimit !== limit && dataSourceFilter) {
            const updated = {
                ...dataSourceFilter,
                limit: selectLimit,
            };
            updateDataSourceFilter(updated);
        }
    };

    return (
        <>
            <FormGroup label="Order by column" htmlFor="order-column">
                <Select
                    inputId="order-column"
                    className="select-field"
                    styles={getSharedStyles<ColumnOption>()}
                    options={columns}
                    onChange={(
                        selectedColumn: {
                            value: string;
                            label: string;
                        } | null,
                    ) => updateOrderColumn(selectedColumn)}
                    placeholder="Select order column"
                    value={orderColumn}
                    noOptionsMessage={() => 'No results found'}
                />
            </FormGroup>

            <FormGroup label="Order direction" htmlFor="order-direction">
                <Select
                    inputId="order-direction"
                    className="select-field"
                    styles={getSharedStyles<{ label: string; value: string }>()}
                    options={orderDirections}
                    onChange={(
                        selectedSort: {
                            value: string;
                            label: string;
                        } | null,
                    ) => updateOrderDirection(selectedSort)}
                    placeholder="Select order direction"
                    value={orderDirection}
                    noOptionsMessage={() => 'No results found'}
                />
            </FormGroup>

            <FormGroup label="Number of records to return (0 for all)" htmlFor="number-of-records">
                <input
                    className="form-control"
                    id="number-of-records"
                    type="number"
                    min="0"
                    max="1000"
                    value={noOfRecords?.toString() || '0'}
                    onChange={(event) => updateNoOfRecords(Number.parseInt(event.target.value, 10))}
                    title="Set the number of records to return"
                />
            </FormGroup>
        </>
    );
};

export default FilterWhereGeneralConfig;
